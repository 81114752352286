<template>
  <div class="callback callback_register"  @click="clickToWrap">
    <div class="callback__wrapper">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-12">
            <div class="callback__top">
              <h2 class="callback__title">ВОЙТИ</h2>
              <div class="callback__desc">
                Пожалуйста, введите e-mail, который вы указали при регистрации
              </div>
            </div>

            <validation-observer tag="form" v-slot="{ invalid, valid }" @submit.prevent="sendForm"
                                 class="form">
              <div class="row">
                <div class="form__wrap" v-for="(item, index) in form" :key="index" :class="item.class">
                  <form-input v-if="item.typeForm === 'input'" :form="item" :index="index"
                              @changeVal="changeVal"></form-input>
                </div>
                <div class="callback__footer">
                  <div class="col-12 col-md-12">
                    <div class="form__button">
                      <div class="row d-flex align-items-center">
                        <div class="col-12 col-sm-12">
                          <button class="button button_blue"
                                  :disabled="!(valid)">Войти
                          </button>
                        </div>
                      </div>
                    </div>
<!--                    <hr class="callback__hr">-->
<!--                    <div class="callback__change">-->
<!--                      <div class="row">-->
<!--                        <div class="col-12 col-md-6">-->
<!--                          Нет аккаунта?-->
<!--                          <a href="#" @click.prevent="$store.commit('changeRightModal', 'register')">Регистрация-->
<!--                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"-->
<!--                                 xmlns="http://www.w3.org/2000/svg">-->
<!--                              <path d="M1 7H13" stroke="#00476D" stroke-width="2"-->
<!--                                    stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                              <path d="M7.75 1.75L13 7L7.75 12.25" stroke="#00476D"-->
<!--                                    stroke-width="2" stroke-linecap="round"-->
<!--                                    stroke-linejoin="round"/>-->
<!--                            </svg>-->
<!--                          </a>-->
<!--                        </div>-->
<!--&lt;!&ndash;                        <div class="col-12 col-md-6 text-end">&ndash;&gt;-->
<!--&lt;!&ndash;                          <a href="#" @click.prevent="$store.commit('changeRightModal', 'forgot')">Забыли пароль?</a>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--                      </div>-->

<!--                    </div>-->
                  </div>

                </div>
              </div>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInput from "../components/form/FormInput";
import _ from "lodash";

export default {
  name: "Login",
  components: {FormInput},
  data() {
    return {
      form: [
        {
          name: 'email',
          type: 'email',
          value: '',
          rules: 'required',
          nameRus: 'E-mail',
          typeForm: 'input',
          placeholder: 'E-mail',
          class: 'col-12'
        },
      ]
    }
  },
  methods: {
    clickToWrap(e) {
      if (e.target.classList.contains('callback')) {
        this.$store.commit('changeRightModal', null)
      }
    },
    sendForm() {
      let data = {}
      for (let item of this.form) {
        data[item.name] = item.value
      }
      this.$axios.post('/auth/login', data)
          .then(resp => {
            const isAdmin = !!_.find(resp.data.user.Role, role => role.name === 'admin')
            this.$store.commit('changeRightModal', null)
            if (!isAdmin) {
              if (Number(resp.data.user.UserInfo.status) === 0) {
                this.$modal.show('fail-login', {text: 'Вам отказано в участии в мероприятии'})
                return false
              }
              if (resp.data.user.UserInfo.status === null) {
                this.$modal.show('fail-login', {text: 'Ваше участие еще не подтвердили'})
                return false
              }
            }
            this.$cookie.set('token', resp.data.token)
            this.$store.commit('changeUser', resp.data.user)
            this.$cookie.set('rememberToken', resp.data.rememberToken)
            this.$axios.defaults.headers.common = {'Authorization': `Bearer ${resp.data.token}`}
            for (let item in this.form) {
              this.form[item].value = ''
            }
          })
          .catch(e => {
            console.log(e)
            this.$modal.show('fail-login', {text: e?.response?.data?.message || 'Ошибка'})
          })
    },
    changeVal(e) {
      this.form[e.index].value = e.val
    }
  }

}
</script>

<style scoped lang="scss">
.callback {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  z-index: 100;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.26);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5vh 0;
  .form-check {
    font-size: rem(12);
  }
  &__wrapper {
    position: relative;
    max-height: 100%;
    overflow: auto;
    background: #FFFFFF;
    opacity: 0.95;
    box-shadow: 0px 0px 10px 1px rgba(45, 180, 239, 0.5);
    border-radius: 10px;
    max-width: 305px;
    padding: 0px 10px;
  }
  &__top {
    position: sticky;
    top: 0;
    padding-top: 25px;
    background-color: #fff;
    z-index: 3;
  }

  &__logo {
    position: absolute;
    top: 0;
    right: 0;
    @include media(992) {
      display: none;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: rem(20);
    text-align: center;
    padding-bottom: 10px;

    color: #000000;
    @include media(768) {
      font-size: rem(32);
    }
  }

  &__desc {
    max-width: 625px;
    font-style: normal;
    font-weight: 300;
    font-size: rem(14);
    line-height: 1.3;
    color: #292929;
  }


  &__close {
    font-style: normal;
    font-weight: 300;
    font-size: rem(14);
    color: #393939;
    text-decoration: none;
    @include media(600) {
      display: inline-block;
      margin-top: rem(10);
    }
  }

  //&__footer {
  //  position: absolute;
  //  left: 0;
  //  right: 0;
  //  bottom: 0;
  //  z-index: 10;
  //}
  &__footer {
    position: sticky;
    bottom: 0;
    z-index: 10;
    @media screen and (max-height: 1079px) {
      position: relative;
      padding-bottom: rem(20);
    }
  }

  &__hr {
    background-color: #7F7F7F;
    border: none;
    height: 2px;
    margin: 20px 0;
  }

  &__change {
    font-style: normal;
    font-weight: normal;
    font-size: rem(16);
    color: #000000;

    a {
      text-decoration: none;
      color: #00476D;
    }
  }
}
</style>
