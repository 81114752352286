<template>
    <div class="form__item">
        <div class="row ">
            <div class="col-12">
                <validation-provider tag="div" :rules="form.rules" v-slot="{errors, touched}" class=""
                                     :name="form.nameRus"
                >
                    <vue-tel-input defaultCountry="RU" :type="form.type"
                                   :placeholder="form.placeholder" :name="form.name" v-model="phone"
                                   :class="errors.length && !success && touched ? 'form__input_error' : ''" @input="changeInput" :disabled="status"/>
                    <div class="form__error" v-if="errors.length && !success && touched">{{errors[0]}}</div>
                </validation-provider>
            </div>
<!--            <div class="col-12 col-sm-4">-->
<!--                <button class="button button_blue button_phone" @click.prevent="showForm = true" :disabled="!isValid" v-if="status === null">Подтвердить</button>-->
<!--                <button class="button button_phone button_green" disabled v-else-if="status === true">Подтверждено</button>-->
<!--                <transition name="fade">-->
<!--                    <validate-form v-if="showForm" :phone="phone" @goodVerify="goodVerify" @closeModal="showForm = false"></validate-form>-->
<!--                </transition>-->
<!--            </div>-->
        </div>
    </div>
</template>


<script>

    // import ValidateForm from "./ValidateForm";
    export default {
        name: "FormPhone",
        // components: {ValidateForm},
        props: ['form', 'valid', 'index', 'success'],
        data() {
            return {
                phone: '',
                isValid: false,
                status: null,
                showForm: false
            }
        },
        methods: {
            changeInput(e, valid) {
                this.isValid = valid.valid
            },
            goodVerify() {
                this.$emit('changeVal', {
                    val: this.phone,
                    index: this.index
                })
            }
        },
        watch: {
            phone(val) {
                this.phone = val.replace(/[a-zа-яё]/gi, '')
                this.goodVerify()
            },
        }
    }
</script>

<style scoped lang="scss">

</style>