<template>
    <div class="bottom  d-flex align-items-center ">
        <div class="container">
            <div class="row d-flex align-items-center ">
                <div class="col-12 col-md-12">
<!--                    <home-countdown v-if="this.$store.getters.getEvent.dateStart"/>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import HomeCountdown from "./HomeCountdown";

    export default {
        name: "HomeBottom",
        // components: {HomeCountdown},
    }
</script>

<style scoped lang="scss">
    .bottom {
      min-height:  20vh;
      padding-top: 3vh;
        @include media(768) {
            height: auto;
          min-height: 10vh;
        }
      @media screen and (max-height: 800px) and (max-width: 992px) {
        padding-top: 0vh;

      }
        &__info {
            font-style: normal;
            font-weight: 500;
            font-size: rem(16);
            color: #282938;

        }
    }
</style>
