import axios from 'axios'
import Cookie from "js-cookie";

axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL
axios.defaults.withCredentials = true

axios.interceptors.request.use((request) => {
    if (request.url.indexOf('auth') === -1) {
        if(!request.headers.common['Authorization']) {
            if (Cookie.get('token')) {
                request.headers.common['Authorization'] = `Bearer ${Cookie.get('token')}`
            }
        }
    }
    return request
})
axios.interceptors.response.use(response => {
    return response
}, async error => {
    if (error.response.status === 401) {
        if (Cookie.get('rememberToken')) {
            const resp = await axios.post('auth/refresh', {token: Cookie.get('rememberToken')})
            axios.defaults.headers.common = {'Authorization': `Bearer ${resp.data.token}`}
            await Cookie.set('token', resp.data.token, { expires: 1/24 })
            await Cookie.set('rememberToken', resp.data.rememberToken, { expires: 60 })
        }
    }
    return Promise.reject(error);
})

export default axios