import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import getCsrf from "../middleware/getCsrf";
import isAuth from "../middleware/isAuth";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      middleware: [getCsrf]
    }
  },
  {
    path: '/verification',
    name: 'Verification',
    component: Home,
    meta: {
      middleware: [getCsrf]
    }
  },
  {
    path: '/votes',
    name: 'Votes',
    component: () => import('../views/Votes'),
    meta: {
      middleware: [isAuth]
    }
  },
  {
    path: '/telecast',
    name: 'Telecast',
    component: () => import('../views/Telecast'),
    meta: {
      middleware: [isAuth]
    }
  },
  {
    path: '/materials',
    name: 'Material',
    component: () => import('../views/Materials'),
  },
  {
    path: '/partners/iva-technologies',
    name: 'FirstPartner',
    component: () => import('../views/partners/FirstPartner'),
  },
  {
    path: '/partners/icl-techno',
    name: 'SecondPartner',
    component: () => import('../views/partners/SecondPartner'),
  },
  {
    path: '/votes/:id',
    name: 'VotesItem',
    component: () => import('../views/VotesItem'),
    meta: {
      middleware: [isAuth]
    }
  },
  {
    path: '/conference-materials',
    name: 'ConferenceMaterials',
    component: () => import('../views/ConferenceMaterials.vue'),
    meta: {
      middleware: [isAuth]
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



export default router
