import axios from "../plugins/axios";
import Cookie from "js-cookie";
export default ({store, next}) => {
    if (store.getters.isAuth) {
        axios.defaults.headers.common = {'Authorization': `Bearer ${Cookie.get('token')}`}
        return next()
    }
    else {
        return next({name: 'Home'})
    }
}