<template>
  <section class="section section_faq" id="section-faq">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="title title_dark title_second title_svg">FAQ
          </h2>
          <div class="title_green title__desc title_uppercase">Часто задаваемые вопросы</div>
          <div class="faq">
            <div class="faq__item" v-for="faq in getFaq" :key="faq.id">
              <div class="faq__head" @click="active.indexOf(faq.id) === -1 ? pushActive(faq.id) : removeActive(faq.id)">
                <div class="row">
                  <div class="col-12">
                    <div class="faq__title">
                      {{ faq.content.name }}
                    </div>
                  </div>
<!--                  <div class="col-2 col-sm-1 text-end">-->
<!--                    <div class="faq__cross">-->
<!--                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"-->
<!--                           >-->
<!--                        <path opacity="0.7"-->
<!--                              d="M16 8.71198H8.88802V16H7.11198V8.71198H0V7.28802H7.11198V0H8.88802V7.28802H16V8.71198Z"-->
<!--                              fill="#0079C1"/>-->
<!--                      </svg>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </div>
              <vue-slide-toggle :open="active.indexOf(faq.id) !== -1" :duration="500">
                <div class="faq__content">
                  <div class="container">
                    <div class="row">
                      <div class="col-12">
                        <div class="faq__text" v-html="faq.content.text"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </vue-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    return {
      active: [],
    }
  },
  computed: {
    getFaq() {
      return this.$store.getters.getContentByType('faq')
    }
  },
  methods: {
    pushActive(id) {
      this.active.push(id)
    },
    removeActive(id) {
      const index = this.active.indexOf(id)
      this.active.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.faq {
  padding-top: rem(30);

  &__head {
    padding: rem(20) 10px;
    cursor: pointer;
    background: #F4F4F4;
    border-radius: 10px;
  }

  &__title {
    font-size: rem(18);
    color: #28282E;
  }

  &__cross {
    svg {
      cursor: pointer;
      vertical-align: middle;
    }
    @include media(600) {
      //padding-right: rem(10);
    }
  }

  &__content {
    background-color: #fff;
    padding: rem(20) 0;
  }

  &__text {
    font-size: rem(18);
    color: #28282E;
  }
  &__item {
    margin-bottom: 6px;
  }
}
</style>
