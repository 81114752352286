<template>
  <modal name="callback-modal"  class="fail-login" :adaptive="true" :height="'auto'" :maxWidth="341">
    <div class="popup__wrapper">
      <div class="popup__title">
        Если возникли проблемы, опишите их
      </div>
      <div class="popup__desc">
        <validation-observer tag="form" class="form" v-slot="{ invalid, valid }" @submit.prevent="sendForm">
          <div class="row">
            <div class="form__wrap" v-for="(item, index) in form" :key="index" :class="item.class">
              <form-input v-if="item.typeForm === 'input' && !$store.getters.getUser" :form="item" :index="index"
                          @changeVal="changeVal"></form-input>
              <validation-provider v-else-if="item.typeForm === 'textarea'" tag="div" :rules="item.rules" v-slot="{errors, touched}" class="form__item"
                                   :name="item.nameRus"
              >
                <textarea v-model="item.value"
                       :ref="item.name"
                       :vid="item.name"
                          :placeholder="item.placeholder" :name="item.name" class="form__textarea" :class="errors.length && touched ? 'form__input_error' : ''"></textarea>
                <div class="form__error" v-if="errors.length && touched">{{errors[0]}}</div>
              </validation-provider>
            </div>
            <div class="form__item">
              <button class="button button_blue"
                      :disabled="!(valid)">Отправить
              </button>
              <div style="color: green;font-size: 12px; text-align:center;padding-top: 5px" v-if="success">{{success}}</div>
            </div>
          </div>
        </validation-observer>
      </div>
      <div class="popup__button">
<!--        <button class="button button_blue" @click="$modal.hide('callback-modal')">ок</button>-->
      </div>
    </div>
  </modal>
</template>

<script>
import FormInput from "../components/form/FormInput";
export default {
  name: "CallbackForm",
  components: {FormInput},
  data() {
    return {
      success: false,
      form: [
        {
          name: 'email',
          type: 'email',
          value: '',
          rules: 'required',
          nameRus: 'E-mail',
          typeForm: 'input',
          placeholder: 'E-mail',
          class: 'col-12'
        },
        {
          name: 'name',
          type: 'text',
          value: '',
          rules: 'required',
          nameRus: 'Имя',
          typeForm: 'input',
          placeholder: 'Имя',
          class: 'col-12'
        },
        {
          name: 'text',
          type: 'text',
          value: '',
          rules: 'required',
          nameRus: 'Комментарий',
          typeForm: 'textarea',
          placeholder: 'Комментарий',
          class: 'col-12 col-md-12'
        },
      ]
    }
  },
  methods: {
    sendForm() {
      let data = {}
      for (let item of this.form) {
        data[item.name] = item.value
      }
      if (this.$store.getters.getUser) {
        let user = this.$store.getters.getUser?.UserInfo?.information
        if (user && typeof user !== "object") {
          user = JSON.parse(user)
        }
        else if (!user) {
          user = {}
        }

        data.name = `${user.firstName} ${user.lastName} ${user.middleName}`
        data.email = this.$store.getters.getUser.email
      }
      this.$axios.post('callback', data)
        .then(resp => {
          this.form[1].value = ''
          this.success = resp.data.message
        })
      return false
    },
    changeVal(e) {
      this.form[e.index].value = e.val
    }
  },
}
</script>

<style scoped lang="scss">
  .form {
    &__item {
      padding-top: 5px;
    }
  }
  .fail-login {
    &::v-deep {
      .vm--modal {
        border-radius: 10px;
      }
    }
  }
  .popup__desc {
    padding-bottom: 0;
  }
</style>
