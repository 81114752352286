<template>
    <validation-provider tag="div" :rules="form.rules" v-slot="{errors}" class="form__item"
                         :name="form.nameRus"
    >
        <p class="form__title">{{form.placeholder}}</p>
        <select :name="form.name" @change="changeVal" class="form__select">
            <option :value="item.value" v-for="(item, index) in form.values" :key="index">{{item.nameRus}}</option>
        </select>
        <div class="form__error" v-if="errors.length && !success">{{errors[0]}}</div>
    </validation-provider>
</template>

<script>
    export default {
        name: "FormSelect",
        props: ['form', 'index', 'success'],
        methods: {
            changeVal(e) {
                console.log(e.target.value)
                this.$emit('changeVal', {
                    val: e.target.value,
                    index: this.index
                })
            }
        }
    }
</script>

<style scoped lang="scss">
.form__title {
  font-size: 14px;
  color: #292929;
  font-weight: 300;
  margin-bottom: 0;
  padding-bottom:5px;
}
select {
  border-radius: 10px;
  padding: 0 10px;
  border: 1px solid #C7C7C7;
  height: 38px;
  &:focus {
    outline: none;
    border-color: #52B5E0;
  }
}
</style>