<template>
  <section class="section section_program" id="section-program">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="title title_dark title_second title_svg text-uppercase">Программа конференции
            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_226_668)">
                <circle cx="19" cy="18" r="18" transform="rotate(-90 19 18)" fill="#59B980"/>
              </g>
              <path
                  d="M15.779 27.0592L16.0039 27.1096L16.1883 26.9713L24.3651 20.8412L24.3659 20.8406C25.6264 19.8918 26.3574 18.7758 26.3574 17.5713C26.3574 16.3668 25.6264 15.2508 24.3659 14.302L24.3651 14.3014L16.1883 8.17123L16.0039 8.03298L15.779 8.0834L15.4623 8.15441L14.4039 8.3917L15.2718 9.04235L23.4482 15.1723C24.5481 15.9984 24.9975 16.8291 24.9975 17.5713C24.9975 18.3135 24.5482 19.1441 23.4484 19.9702C23.4483 19.9702 23.4482 19.9703 23.4482 19.9703L15.2718 26.1002L14.4039 26.7509L15.4623 26.9882L15.779 27.0592Z"
                  fill="white" stroke="white"/>
              <defs>
                <filter id="filter0_d_226_668" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                 result="hardAlpha"/>
                  <feOffset dy="1"/>
                  <feGaussianBlur stdDeviation="0.5"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.34902 0 0 0 0 0.72549 0 0 0 0 0.501961 0 0 0 0.77 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_226_668"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_226_668" result="shape"/>
                </filter>
              </defs>
            </svg>

          </h2>
          <div class="program" v-for="prog in getProgram" :key="prog.id">
            <div class="row">
              <div class="col-12 col-lg-4">
                <div class="programs__header">
                  <div class="program__items" v-for="item in prog.sections" :key="item.id">
                    <div class="program__head" @click="changeActive(item.id)"
                         :class="item.id === activeProgram ? 'active' : ''">
                      <div class="container">
                        <div class="row">
                          <div class="col-12">
                            <span class="program__area">
                              {{ item.name }}
                            </span>
                            <span class="program__themes">
                              | <span v-html="item.theme"></span>
                            </span>
                          </div>
<!--                          <div class="col-2 text-end d-flex align-items-center justify-content-end" v-if="item.id !== activeProgram">-->
<!--                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"-->
<!--                                 xmlns="http://www.w3.org/2000/svg">-->
<!--                              <path opacity="0.7"-->
<!--                                    d="M16 8.71198H8.88802V16H7.11198V8.71198H0V7.28802H7.11198V0H8.88802V7.28802H16V8.71198Z"-->
<!--                                    fill="#0079C1"/>-->
<!--                            </svg>-->
<!--                          </div>-->
                        </div>
                      </div>
                    </div>
                    <div class="program__mobile d-lg-none">
                      <vue-slide-toggle :open="item.id === activeProgram" :duration="500" class="program__content">
                        <div class="container">
                          <div class="row">
                            <div class="col-12">
                              <div class="program__item" v-for="val in item.program" :key="val.id">
                                <div class="program__h" @click="toggleItem(val.id)">
                                  <div class="row">
                                    <div class="col-12 col-md-4 col-lg-3">
                                      <div class="program__time">{{ val.time }}</div>
                                    </div>
                                    <div class="col-12 col-md-7 col-lg-8">
                                      <div class="program__theme" v-html="val.name">{{ val.name }}</div>
                                    </div>
                                    <div class="col-1 text-end" v-if="val.content || val.speaker.length">
                                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                           xmlns="http://www.w3.org/2000/svg"
                                           v-if="openPrograms.indexOf(val.id) === -1">
                                        <path opacity="0.7"
                                              d="M16 8.71198H8.88802V16H7.11198V8.71198H0V7.28802H7.11198V0H8.88802V7.28802H16V8.71198Z"
                                              fill="#0079C1"/>
                                      </svg>
                                      <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                           xmlns="http://www.w3.org/2000/svg" v-else>
                                        <path opacity="0.7"
                                              d="M11.4669 12.474L6.43796 7.44508L1.28455 12.5985L0.0286983 11.3426L5.18211 6.18923L0.153176 1.1603L1.16007 0.153409L6.189 5.18234L11.3424 0.0289291L12.5983 1.28478L7.44485 6.43819L12.4738 11.4671L11.4669 12.474Z"
                                              fill="#00476D"/>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <vue-slide-toggle :open="openPrograms.indexOf(val.id) !== -1" v-if="val.content || val.speaker.length"
                                                  :duration="500">
                                  <program-content :program="val"></program-content>
                                </vue-slide-toggle>
                              </div>

                            </div>
                          </div>
                        </div>
                      </vue-slide-toggle>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-8 d-none d-lg-block">
                <div class="program__wrapper">
                  <div class="program__wrap" v-for="item in prog.sections" :key="item.id">
                    <div class="program__content" v-if="item.id === activeProgram">
                      <div class="program__item" v-for="val in item.program" :key="val.id">
                        <div class="program__h" @click="toggleItem(val.id)">
                          <div class="row">
                            <div class="col-3">
                              <div class="program__time">{{ val.time }}</div>
                            </div>
                            <div class="col-8">
                              <div class="program__theme" v-html="val.name"> {{ val.name }}</div>
                            </div>
                            <div class="col-1 text-end" v-if="val.content || val.speaker.length">
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                   xmlns="http://www.w3.org/2000/svg" v-if="openPrograms.indexOf(val.id) === -1">
                                <path opacity="0.7"
                                      d="M16 8.71198H8.88802V16H7.11198V8.71198H0V7.28802H7.11198V0H8.88802V7.28802H16V8.71198Z"
                                      fill="#0079C1"/>
                              </svg>
                              <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                   xmlns="http://www.w3.org/2000/svg" v-else>
                                <path opacity="0.7"
                                      d="M11.4669 12.474L6.43796 7.44508L1.28455 12.5985L0.0286983 11.3426L5.18211 6.18923L0.153176 1.1603L1.16007 0.153409L6.189 5.18234L11.3424 0.0289291L12.5983 1.28478L7.44485 6.43819L12.4738 11.4671L11.4669 12.474Z"
                                      fill="#00476D"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <vue-slide-toggle :open="openPrograms.indexOf(val.id) !== -1"  v-if="val.content || val.speaker.length" :duration="500">
                          <program-content :program="val"></program-content>
                        </vue-slide-toggle>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProgramContent from "../components/ProgramContent";

export default {
  name: "Program",
  components: {ProgramContent},
  data() {
    return {
      activeProgram: 1,
      openPrograms: [],
    }
  },
  computed: {
    getProgram() {
      return this.$store.getters.getProgram
    }
  },
  watch: {
    activeProgram() {
      this.openPrograms = []
    }
  },
  methods: {
    changeActive(id) {
      if (window.screen.width < 992) {
        if (this.activeProgram === id) {
          this.activeProgram = null
        } else {
          this.activeProgram = id
        }
      } else {
        this.activeProgram = id

      }
    },
    toggleItem(id) {
      if (this.openPrograms.indexOf(id) !== -1) {
        this.openPrograms.splice(this.openPrograms.indexOf(id), 1)
      } else {
        this.openPrograms.push(id)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.program {
  padding-top: rem(50);

  &__head {
    background: #E7E7E7;
    border: 1px solid #E4F5FF;
    border-radius: 10px;
    padding: 15px 0;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: rem(5);
    min-height: 65px;
    @include media(992) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.active {
      background: #0079C1;
      color: #fff;
    }

  }

  &__area {
    //text-transform: lowercase;
    font-weight: 500;
    font-size: 16px;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__theme {
    //text-transform: lowercase;
    font-size: 18px;
    font-weight: 300;

    &:first-letter {
      text-transform: uppercase;
    }
    &::v-deep {
      p {
        margin-bottom: 0;
      }
    }

  }

  &__time {
    color: #00476D;
    font-size: rem(21);
    font-weight: 600;
  }

  &__text {

  }

  &__item {
    border-bottom: 2px solid #ECECF1;

  }

  &__h {
    padding: rem(15) 0;
    cursor: pointer;
    position: relative;
    @include media(768) {
      svg {
        position: absolute;
        top: 20px;
        right: 10px;
      }

    }
  }

  &__name {
    font-size: rem(18);
    font-weight: 300;
    color: #282938;
  }

  &__text {
    background: #F4F4F4;
    border-radius: 10px;
    padding: rem(10) 0;
    margin-bottom: rem(10);
  }

  &__theme {
    &::v-deep {
      ul {
        padding: 0;
        margin: 0;
      }

      @include media(768) {
        padding: 0 0px;
      }
    }
  }


}
</style>
