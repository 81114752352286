<template>
  <modal name="fail-login" class="fail-login" :adaptive="true" :height="'auto'" :maxWidth="341" @before-open="beforeOpen">
    <div class="popup__wrapper">
      <div class="popup__title">
        {{title}}
      </div>
      <div class="popup__desc">
        {{message}}
      </div>
      <div class="popup__button">
        <button class="button button_blue" @click="$modal.hide('fail-login')">ок</button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "FailLogin",
  data() {
    return {
      message: '',
      title: '',
    }
  },
  methods: {
    beforeOpen (event) {
      this.message = event.params.text
      this.title = event.params.title || 'Ошибка'
    }
  }

}
</script>

<style scoped lang="scss">
  .popup__wrapper {
    border-radius: 10px;
  }
  .fail-login {
    &::v-deep {
      .vm--modal {
        border-radius: 10px;
      }
    }
  }
</style>