import Vue from 'vue'
import Vuex from 'vuex'
// import axios from "../plugins/axios"
import _ from 'lodash'
import moment from "moment-timezone";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {id: 1},
        rightModal: null,
        event: {
            "id": 1,
            "name": "АСТРАКОНФ-2023",
            "description": "<p><strong>5-я ежегодная практическая конференция</strong></p><p>Мероприятие ориентировано на представителей бизнеса и государственных организаций, госкорпораций и концернов. Посвящено цифровизации, ИТ-импортозамещению, проектам на базе операционной системы Astra Linux и решений из продуктового портфеля ГК «Астра». Цели конференции — выстраивание предметного диалога между компаниями из различных секторов экономики, регуляторами и ведущими российскими разработчиками софта и «железа», а также совместный поиск оптимальных путей для ИТ-трансформации.</p>",
            "dateStart": "2023-04-25T07:00:00.000Z",
            "createdAt": "2023-03-14T15:58:48.000Z",
            "updatedAt": "2023-03-15T13:39:48.000Z"
        },
        settings: null,
        content:
            [
            {
            "id": 1,
            "type": "faq",
            "order": 1,
            "content": {
                "name": "Что требуется для качественного просмотра трансляции?",
                "text": "<p>Наличие стабильного и скоростного интернет-подключения — от 20 мб/с как для входящего, так и для исходящего трафика. Скорость подключения можно проверить, пройдя по следующей ссылке: <a href=\"https://www.speedtest.net/ru\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.speedtest.net/ru</a> </p>"
            },
            "createdAt": "2023-03-14T17:11:51.000Z",
            "updatedAt": "2023-03-20T13:12:08.000Z"
        }, {
            "id": 2,
            "type": "faq",
            "order": 2,
            "content": {
                "name": "Могу ли я подключиться через Wi-Fi?",
                "text": "<p>Рекомендуем подключить устройство напрямую к кабелю Internet или находиться на минимальном расстоянии от Wi-Fi-роутера. Требуется стабильное подключение со скоростью от 20 мб/с как для входящего, так и для исходящего трафика. Скорость интернет-подключения можно проверить, пройдя по следующей ссылке: <a href=\"https://www.speedtest.net/ru\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.speedtest.net/ru</a> </p>"
            },
            "createdAt": "2023-03-14T17:13:06.000Z",
            "updatedAt": "2023-03-20T13:12:13.000Z"
        }, {
            "id": 3,
            "type": "faq",
            "order": 3,
            "content": {
                "name": "Почему у меня изображение низкого качества?",
                "text": "<p>Во время просмотра онлайн-конференции нежелательно обновлять ваше ПО, а также использовать файлообменники (Torrent) или скачивать файлы из Интернета.</p><p>*Из-за недостаточной скорости плеер конференции перестроится на более низкое качество изображения, и качество картинки снизится.</p>"
            },
            "createdAt": "2023-03-14T17:13:31.000Z",
            "updatedAt": "2023-03-14T17:13:31.000Z"
        }, {
            "id": 4,
            "type": "faq",
            "order": 4,
            "content": {"name": "Могу ли я смотреть трансляцию с телефона?", "text": "<p>Да, это возможно.</p>"},
            "createdAt": "2023-03-14T17:13:55.000Z",
            "updatedAt": "2023-03-14T17:13:55.000Z"
        }, {
            "id": 5,
            "type": "faq",
            "order": 5,
            "content": {
                "name": "Какие данные потребуются, чтобы подключиться к конференции и задавать вопросы?",
                "text": "<p>Для просмотра трансляции и доступа к чату вам потребуется авторизоваться на платформе с помощью логина и пароля, указанного при регистрации.</p>"
            },
            "createdAt": "2023-03-14T17:14:21.000Z",
            "updatedAt": "2023-03-14T17:14:21.000Z"
        }, {
            "id": 6,
            "type": "faq",
            "order": 6,
            "content": {
                "name": "Могу ли я задать вопрос спикеру?",
                "text": "<p>Да, такая возможность будет – необходимо выбрать чат рядом с плеером и написать в нем свои вопросы.</p>"
            },
            "createdAt": "2023-03-14T17:16:22.000Z",
            "updatedAt": "2023-03-14T17:16:22.000Z"
        }, {
            "id": 7,
            "type": "faq",
            "order": 7,
            "content": {
                "name": "Что делать, если у меня остались вопросы по технической части?",
                "text": "<p>Вы можете обратиться в нашу службу поддержки, контакты указаны внизу страницы.&nbsp;</p>"
            },
            "createdAt": "2023-03-14T17:16:43.000Z",
            "updatedAt": "2023-03-14T17:16:43.000Z"
        }, {
            "id": 8,
            "type": "faq",
            "order": 8,
            "content": {
                "name": "Будут ли разосланы презентации после трансляции?",
                "text": "<p>Презентации рассылаются организаторами по индивидуальному запросу.</p>"
            },
            "createdAt": "2023-03-14T17:17:06.000Z",
            "updatedAt": "2023-03-20T13:12:29.000Z"
        }, {
            "id": 9,
            "type": "faq",
            "order": 9,
            "content": {
                "name": "Почему я не вижу трансляцию?",
                "text": "<p>Для просмотра трансляции рекомендуем пользоваться браузерами Yandex, Google Chrome, Microsoft Edge, Opera или Safari, Все они должны быть обновлены до актуальной версии.&nbsp;</p>"
            },
            "createdAt": "2023-03-14T17:18:20.000Z",
            "updatedAt": "2023-03-20T13:12:47.000Z"
        }, {
            "id": 10,
            "type": "faq",
            "order": 10,
            "content": {
                "name": "Нужны ли мне наушники с микрофоном для участия в конференции?",
                "text": "<p>Нет, микрофон вам не потребуется. В рамках конференции не планируется общение участников в прямом эфире.</p>"
            },
            "createdAt": "2023-03-14T17:18:45.000Z",
            "updatedAt": "2023-03-14T17:18:45.000Z"
        }, {
            "id": 11,
            "type": "faq",
            "order": 11,
            "content": {
                "name": "Через какой браузер лучше смотреть конференцию?",
                "text": "<p>Рекомендуем пользоваться Yandex, Google Chrome, Microsoft Edge, Opera или Safari. Все браузеры должны быть обновлены до актуальной версии.</p>"
            },
            "createdAt": "2023-03-14T17:19:14.000Z",
            "updatedAt": "2023-03-20T13:13:14.000Z"
        }, {
            "id": 12,
            "type": "faq",
            "order": 12,
            "content": {
                "name": "Как обратиться в техническую поддержку?",
                "text": "<p>При проблемах с регистрацией, работой на платформе, просмотром трансляции, а также по другим техническим вопросам вы можете обратиться в чат технической поддержки. Окно чата всегда находится в правом нижнем углу сайта.</p>"
            },
            "createdAt": "2023-03-14T17:20:21.000Z",
            "updatedAt": "2023-03-14T17:20:21.000Z"
        }, {
            "id": 13,
            "type": "faq",
            "order": 13,
            "content": {
                "name": "Чат технической поддержки",
                "text": "<p>При возникновении проблем с прохождением регистрации, работой на платформе, просмотром окна трансляции и по другим техническим вопросам вы можете обратиться в чат технической поддержки. Он всегда находится в правом нижнем углу сайта.</p>"
            },
            "createdAt": "2023-03-14T17:20:45.000Z",
            "updatedAt": "2023-03-14T17:20:45.000Z"
        }],
        program: [],
        deleteMessage: false,
        partners: [
            {
                "id": 19,
                "name": "CNews",
                "avatar": "/images/Cnews.png",
                "link": "https://www.cnews.ru/",
                "group": "Информационные партнеры",
                "newPage": true,
                "order": 1,
                "hideToIndex": false,
                "createdAt": "2023-03-30T09:13:30.000Z",
                "updatedAt": "2023-03-30T09:13:30.000Z"
            },
            {
                "id": 36,
                "name": "Треолан",
                "avatar": "/images/treolan_logo_rgb.png",
                "link": "https://www.treolan.ru/",
                "group": "Стратегические партнеры",
                "newPage": true,
                "order": 1,
                "hideToIndex": false,
                "createdAt": "2023-04-13T17:43:47.000Z",
                "updatedAt": "2023-04-17T15:39:04.000Z"
            },
            {
                "id": 17,
                "name": "Росэнергоатом",
                "avatar": "/images/росэнергоатом.png",
                "link": "https://www.rosenergoatom.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 1,
                "hideToIndex": false,
                "createdAt": "2023-03-24T13:56:35.000Z",
                "updatedAt": "2023-03-24T13:56:35.000Z"
            },
            {
                "id": 29,
                "name": "Axoft",
                "avatar": "/images/Axoft.png",
                "link": "https://axoftglobal.ru/",
                "group": "Стратегические партнеры",
                "newPage": true,
                "order": 2,
                "hideToIndex": false,
                "createdAt": "2023-04-10T13:02:29.000Z",
                "updatedAt": "2023-04-10T13:02:29.000Z"
            },
            {
                "id": 11,
                "name": "ICT Online",
                "avatar": "/images/Group 109.png",
                "link": "https://ict-online.ru/",
                "group": "Информационные партнеры",
                "newPage": true,
                "order": 2,
                "hideToIndex": false,
                "createdAt": "2023-03-20T10:00:41.000Z",
                "updatedAt": "2023-03-22T14:30:26.000Z"
            },
            {
                "id": 15,
                "name": "Infotecs",
                "avatar": "/images/infotecs_logo2.png",
                "link": "https://infotecs.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 2,
                "hideToIndex": false,
                "createdAt": "2023-03-23T11:00:38.000Z",
                "updatedAt": "2023-03-24T13:53:17.000Z"
            },
            {
                "id": 32,
                "name": "Аквариус",
                "avatar": "/images/Aquarius.png",
                "link": "https://www.aq.ru/",
                "group": "Стратегические партнеры",
                "newPage": true,
                "order": 3,
                "hideToIndex": false,
                "createdAt": "2023-04-11T12:33:36.000Z",
                "updatedAt": "2023-04-11T12:33:36.000Z"
            },
            {
                "id": 13,
                "name": "IT World",
                "avatar": "/images/itworld.jpg",
                "link": "https://www.it-world.ru/",
                "group": "Информационные партнеры",
                "newPage": true,
                "order": 3,
                "hideToIndex": false,
                "createdAt": "2023-03-22T13:57:33.000Z",
                "updatedAt": "2023-03-22T14:03:06.000Z"
            },
            {
                "id": 42,
                "name": "Delta",
                "avatar": "/images/delta.png",
                "link": "https://deltacomputers.ru/",
                "group": "Стратегические партнеры",
                "newPage": true,
                "order": 4,
                "hideToIndex": false,
                "createdAt": "2023-04-14T17:08:42.000Z",
                "updatedAt": "2023-04-17T15:39:12.000Z"
            },
            {
                "id": 16,
                "name": "TrueConf",
                "avatar": "/images/infotecs_logo.png",
                "link": "https://trueconf.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 4,
                "hideToIndex": false,
                "createdAt": "2023-03-24T13:52:29.000Z",
                "updatedAt": "2023-03-24T13:52:29.000Z"
            },
            {
                "id": 14,
                "name": "OCS Distribution",
                "avatar": "/images/rupost (1).png",
                "link": "https://www.ocs.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 5,
                "hideToIndex": false,
                "createdAt": "2023-03-23T10:57:12.000Z",
                "updatedAt": "2023-03-23T10:57:12.000Z"
            },
            {
                "id": 24,
                "name": "Открытые системы",
                "avatar": "/images/открытые системы.png",
                "link": "https://www.osp.ru/",
                "group": "Информационные партнеры",
                "newPage": true,
                "order": 5,
                "hideToIndex": false,
                "createdAt": "2023-04-06T07:24:03.000Z",
                "updatedAt": "2023-04-06T07:24:03.000Z"
            },
            {
                "id": 18,
                "name": "Гарант",
                "avatar": "/images/garant.png",
                "link": "http://linux.garant.ru/?utm_source=astraconf&utm_medium=affiliate&utm_campaign=conf",
                "group": "Партнеры",
                "newPage": true,
                "order": 5,
                "hideToIndex": false,
                "createdAt": "2023-03-28T13:28:04.000Z",
                "updatedAt": "2023-04-10T13:39:26.000Z"
            },
            {
                "id": 10,
                "name": "Global CIO",
                "avatar": "/images/Group 108.png",
                "link": "https://globalcio.ru/",
                "group": "Информационные партнеры",
                "newPage": true,
                "order": 5,
                "hideToIndex": false,
                "createdAt": "2023-03-20T09:59:27.000Z",
                "updatedAt": "2023-03-30T09:12:51.000Z"
            },
            {
                "id": 9,
                "name": "ICL Техно",
                "avatar": "/images/icl2.png",
                "link": "http://icl-techno.ru",
                "group": "Стратегические партнеры",
                "newPage": true,
                "order": 5,
                "hideToIndex": false,
                "createdAt": "2023-03-20T09:57:16.000Z",
                "updatedAt": "2023-04-17T15:38:28.000Z"
            },
            {
                "id": 4,
                "name": "RuPost",
                "avatar": "/images/rupost.png",
                "link": "https://www.rupost.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 6,
                "hideToIndex": false,
                "createdAt": "2023-03-20T09:18:44.000Z",
                "updatedAt": "2023-03-20T10:56:02.000Z"
            },
            {
                "id": 28,
                "name": "Tadviser",
                "avatar": "/images/tadviser.png",
                "link": "https://www.tadviser.ru/",
                "group": "Информационные партнеры",
                "newPage": true,
                "order": 6,
                "hideToIndex": false,
                "createdAt": "2023-04-10T12:34:55.000Z",
                "updatedAt": "2023-04-10T12:35:38.000Z"
            },
            {
                "id": 43,
                "name": "Russoft",
                "avatar": "/images/russoft2.jpg",
                "link": "https://russoft.org/",
                "group": "Информационные партнеры",
                "newPage": true,
                "order": 7,
                "hideToIndex": false,
                "createdAt": "2023-04-14T17:23:21.000Z",
                "updatedAt": "2023-04-14T17:23:21.000Z"
            },
            {
                "id": 3,
                "name": "BILLmanager",
                "avatar": "/images/bill.png",
                "link": "https://www.ispsystem.ru",
                "group": "Партнеры",
                "newPage": true,
                "order": 7,
                "hideToIndex": false,
                "createdAt": "2023-03-20T09:14:45.000Z",
                "updatedAt": "2023-04-21T13:35:53.000Z"
            },
            {
                "id": 44,
                "name": "Anti Malware",
                "avatar": "/images/Anti Malware2.png",
                "link": "https://www.anti-malware.ru",
                "group": "Информационные партнеры",
                "newPage": true,
                "order": 8,
                "hideToIndex": false,
                "createdAt": "2023-04-17T07:43:56.000Z",
                "updatedAt": "2023-04-17T07:45:33.000Z"
            },
            {
                "id": 20,
                "name": "Mont",
                "avatar": "/images/mont.png",
                "link": "https://www.mont.ru/ru-ru",
                "group": "Партнеры",
                "newPage": true,
                "order": 8,
                "hideToIndex": false,
                "createdAt": "2023-03-31T14:30:50.000Z",
                "updatedAt": "2023-03-31T14:30:50.000Z"
            },
            {
                "id": 2,
                "name": "ALD Pro",
                "avatar": "/images/ald.png",
                "link": "http://aldpro.ru",
                "group": "Партнеры",
                "newPage": true,
                "order": 9,
                "hideToIndex": false,
                "createdAt": "2023-03-17T08:17:26.000Z",
                "updatedAt": "2023-03-20T10:32:51.000Z"
            },
            {
                "id": 45,
                "name": "Bis TV",
                "avatar": "/images/лого новый на прозрачной.png",
                "link": "https://ib-bank.ru/",
                "group": "Информационные партнеры",
                "newPage": true,
                "order": 9,
                "hideToIndex": false,
                "createdAt": "2023-04-17T08:12:28.000Z",
                "updatedAt": "2023-04-17T08:12:28.000Z"
            },
            {
                "id": 46,
                "name": "Код информационной безопасности",
                "avatar": "/images/Код Иб.png",
                "link": "https://codeib.ru/",
                "group": "Информационные партнеры",
                "newPage": true,
                "order": 9,
                "hideToIndex": false,
                "createdAt": "2023-04-17T13:07:38.000Z",
                "updatedAt": "2023-04-17T13:07:38.000Z"
            },
            {
                "id": 1,
                "name": " RuBackup",
                "avatar": "/images/rubackup.png",
                "link": "https://rubackup.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 10,
                "hideToIndex": false,
                "createdAt": "2023-03-17T08:16:28.000Z",
                "updatedAt": "2023-03-17T08:16:28.000Z"
            },
            {
                "id": 53,
                "name": "Cyber Media",
                "avatar": "/images/кибермедиа.png",
                "link": "https://securitymedia.org/",
                "group": "Информационные партнеры",
                "newPage": true,
                "order": 10,
                "hideToIndex": false,
                "createdAt": "2023-04-20T12:14:41.000Z",
                "updatedAt": "2023-04-20T12:18:36.000Z"
            },
            {
                "id": 21,
                "name": "Tegrus",
                "avatar": "/images/tegrus.png",
                "link": "https://tegrus.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 12,
                "hideToIndex": false,
                "createdAt": "2023-03-31T15:32:50.000Z",
                "updatedAt": "2023-03-31T15:32:50.000Z"
            },
            {
                "id": 6,
                "name": "Брест",
                "avatar": "/images/brest.png",
                "link": "https://astralinux.ru/products/pk-brest/",
                "group": "Партнеры",
                "newPage": true,
                "order": 12,
                "hideToIndex": false,
                "createdAt": "2023-03-20T09:32:31.000Z",
                "updatedAt": "2023-03-20T10:33:17.000Z"
            },
            {
                "id": 7,
                "name": "Termidesk",
                "avatar": "/images/termidesk.png",
                "link": "https://termidesk.ru",
                "group": "Партнеры",
                "newPage": true,
                "order": 13,
                "hideToIndex": false,
                "createdAt": "2023-03-20T09:41:01.000Z",
                "updatedAt": "2023-03-20T10:33:28.000Z"
            },
            {
                "id": 8,
                "name": "Workspad",
                "avatar": "/images/workspad.png",
                "link": "https://www.workspad.ru",
                "group": "Партнеры",
                "newPage": true,
                "order": 14,
                "hideToIndex": false,
                "createdAt": "2023-03-20T09:43:23.000Z",
                "updatedAt": "2023-03-20T10:34:35.000Z"
            },
            {
                "id": 12,
                "name": "Tantor",
                "avatar": "/images/tantor.png",
                "link": "https://tantorlabs.ru",
                "group": "Партнеры",
                "newPage": true,
                "order": 15,
                "hideToIndex": false,
                "createdAt": "2023-03-20T10:34:27.000Z",
                "updatedAt": "2023-03-20T10:34:27.000Z"
            },
            {
                "id": 23,
                "name": "К2Тех",
                "avatar": "/images/к2 тех.png",
                "link": "https://k2.tech/",
                "group": "Партнеры",
                "newPage": true,
                "order": 16,
                "hideToIndex": false,
                "createdAt": "2023-04-05T14:24:54.000Z",
                "updatedAt": "2023-04-17T15:30:25.000Z"
            },
            {
                "id": 22,
                "name": "Гравитон",
                "avatar": "/images/гравитон 4.png",
                "link": "https://graviton.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 17,
                "hideToIndex": false,
                "createdAt": "2023-04-03T14:04:51.000Z",
                "updatedAt": "2023-04-03T14:11:21.000Z"
            },
            {
                "id": 25,
                "name": "Код безопасности",
                "avatar": "/images/код безопасности.png",
                "link": "https://www.securitycode.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 18,
                "hideToIndex": false,
                "createdAt": "2023-04-06T07:30:01.000Z",
                "updatedAt": "2023-04-06T07:30:01.000Z"
            },
            {
                "id": 26,
                "name": "AT Consulting",
                "avatar": "/images/Лига цифровой 4.png",
                "link": "https://digitalleague.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 19,
                "hideToIndex": false,
                "createdAt": "2023-04-07T13:52:35.000Z",
                "updatedAt": "2023-04-10T17:44:05.000Z"
            },
            {
                "id": 47,
                "name": "Арпп",
                "avatar": "/images/АрПП.png",
                "link": "https://arppsoft.ru/",
                "group": "Информационные партнеры",
                "newPage": true,
                "order": 19,
                "hideToIndex": false,
                "createdAt": "2023-04-17T13:11:24.000Z",
                "updatedAt": "2023-04-17T13:11:24.000Z"
            },
            {
                "id": 51,
                "name": "IXBT",
                "avatar": "/images/ixbt.png",
                "link": "https://www.ixbt.com/",
                "group": "Информационные партнеры",
                "newPage": true,
                "order": 20,
                "hideToIndex": false,
                "createdAt": "2023-04-19T10:35:21.000Z",
                "updatedAt": "2023-04-19T10:50:48.000Z"
            },
            {
                "id": 58,
                "name": "COMNEWS",
                "avatar": "/images/comnews.png",
                "link": "https://www.comnews.ru/",
                "group": "Информационные партнеры",
                "newPage": true,
                "order": 20,
                "hideToIndex": false,
                "createdAt": "2023-04-24T15:13:28.000Z",
                "updatedAt": "2023-04-24T15:20:13.000Z"
            },
            {
                "id": 27,
                "name": "MasterSCADA",
                "avatar": "/images/мастерскада.png",
                "link": "https://masterscada.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 20,
                "hideToIndex": false,
                "createdAt": "2023-04-07T18:10:39.000Z",
                "updatedAt": "2023-04-07T18:11:46.000Z"
            },
            {
                "id": 30,
                "name": "ContentAI",
                "avatar": "/images/Content AI.png",
                "link": "https://contentai.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 21,
                "hideToIndex": false,
                "createdAt": "2023-04-10T13:09:03.000Z",
                "updatedAt": "2023-04-10T13:09:03.000Z"
            },
            {
                "id": 31,
                "name": "р7 ОФИС",
                "avatar": "/images/R7.png",
                "link": "https://r7-office.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 22,
                "hideToIndex": false,
                "createdAt": "2023-04-10T13:10:34.000Z",
                "updatedAt": "2023-04-13T15:14:23.000Z"
            },
            {
                "id": 33,
                "name": "Мерлион",
                "avatar": "/images/мерлион.png",
                "link": "https://merlion.com",
                "group": "Партнеры",
                "newPage": true,
                "order": 24,
                "hideToIndex": false,
                "createdAt": "2023-04-11T12:57:49.000Z",
                "updatedAt": "2023-04-11T12:59:00.000Z"
            },
            {
                "id": 35,
                "name": "М ифногруп",
                "avatar": "/images/инфогруп.png",
                "link": "https://m-infogroup.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 25,
                "hideToIndex": false,
                "createdAt": "2023-04-13T17:39:53.000Z",
                "updatedAt": "2023-04-13T17:39:53.000Z"
            },
            {
                "id": 34,
                "name": "Газпром",
                "avatar": "/images/1_GPN_logo_rus_blue-on-white.cdr.svg",
                "link": "https://www.gazprom-neft.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 26,
                "hideToIndex": false,
                "createdAt": "2023-04-12T15:17:04.000Z",
                "updatedAt": "2023-04-12T15:17:04.000Z"
            },
            {
                "id": 37,
                "name": "Сбер Интегро",
                "avatar": "/images/сберинтегро.png",
                "link": "https://sberintegro.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 27,
                "hideToIndex": false,
                "createdAt": "2023-04-13T17:49:22.000Z",
                "updatedAt": "2023-04-13T17:54:10.000Z"
            },
            {
                "id": 38,
                "name": "Soflab",
                "avatar": "/images/1_GPN_logo_rus_blue-on-white.cdr (2).png",
                "link": "https://www.softlab.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 28,
                "hideToIndex": false,
                "createdAt": "2023-04-14T16:54:51.000Z",
                "updatedAt": "2023-04-14T16:57:32.000Z"
            },
            {
                "id": 39,
                "name": "Biplane",
                "avatar": "/images/biplane.png",
                "link": "https://biplane24.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 29,
                "hideToIndex": false,
                "createdAt": "2023-04-14T17:01:21.000Z",
                "updatedAt": "2023-04-14T17:01:21.000Z"
            },
            {
                "id": 40,
                "name": "HiTech",
                "avatar": "/images/hitech.png",
                "link": "https://hi-tech.org/",
                "group": "Партнеры",
                "newPage": true,
                "order": 30,
                "hideToIndex": false,
                "createdAt": "2023-04-14T17:03:36.000Z",
                "updatedAt": "2023-04-14T17:03:36.000Z"
            },
            {
                "id": 41,
                "name": "s-terra",
                "avatar": "/images/sterra.png",
                "link": "https://www.s-terra.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 31,
                "hideToIndex": false,
                "createdAt": "2023-04-14T17:06:33.000Z",
                "updatedAt": "2023-04-14T17:06:33.000Z"
            },
            {
                "id": 48,
                "name": "Irbis",
                "avatar": "/images/Ирбис 2.png",
                "link": "https://www.irbis.su/",
                "group": "Партнеры",
                "newPage": true,
                "order": 33,
                "hideToIndex": false,
                "createdAt": "2023-04-17T14:53:22.000Z",
                "updatedAt": "2023-04-17T14:55:04.000Z"
            },
            {
                "id": 49,
                "name": "ВК КЛАУД",
                "avatar": "/images/Вк клауд.png",
                "link": "https://mcs.mail.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 34,
                "hideToIndex": false,
                "createdAt": "2023-04-17T14:58:41.000Z",
                "updatedAt": "2023-04-17T14:58:41.000Z"
            },
            {
                "id": 50,
                "name": "ИВА",
                "avatar": "/images/Ива2.png",
                "link": "https://iva-tech.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 35,
                "hideToIndex": false,
                "createdAt": "2023-04-17T14:59:18.000Z",
                "updatedAt": "2023-04-21T14:36:23.000Z"
            },
            {
                "id": 52,
                "name": "инферит",
                "avatar": "/images/инферит.png",
                "link": "https://inferit.com/",
                "group": "Партнеры",
                "newPage": true,
                "order": 36,
                "hideToIndex": false,
                "createdAt": "2023-04-20T08:03:15.000Z",
                "updatedAt": "2023-04-21T14:36:35.000Z"
            },
            {
                "id": 54,
                "name": "МойОфис",
                "avatar": "/images/мойофис.png",
                "link": "https://myoffice.ru/",
                "group": "Партнеры",
                "newPage": true,
                "order": 37,
                "hideToIndex": false,
                "createdAt": "2023-04-21T13:07:06.000Z",
                "updatedAt": "2023-04-24T12:48:00.000Z"
            },
            {
                "id": 55,
                "name": "DCImanager",
                "avatar": "/images/dci.png",
                "link": "https://www.ispsystem.ru/dcimanager",
                "group": "Партнеры",
                "newPage": false,
                "order": 38,
                "hideToIndex": false,
                "createdAt": "2023-04-21T14:32:35.000Z",
                "updatedAt": "2023-04-21T14:36:59.000Z"
            },
            {
                "id": 56,
                "name": "VMmanager",
                "avatar": "/images/vm.png",
                "link": "https://www.ispsystem.ru/vmmanager",
                "group": "Партнеры",
                "newPage": false,
                "order": 39,
                "hideToIndex": false,
                "createdAt": "2023-04-21T14:50:22.000Z",
                "updatedAt": "2023-04-21T14:50:22.000Z"
            },
            {
                "id": 57,
                "name": "Awada",
                "avatar": "/images/Авада.png",
                "link": "http://awada.ru",
                "group": "Партнеры",
                "newPage": true,
                "order": 40,
                "hideToIndex": false,
                "createdAt": "2023-04-21T15:05:00.000Z",
                "updatedAt": "2023-04-21T15:05:00.000Z"
            }
        ]
    },
    getters: {
        getUser(state) {
            return state.user
        },
        getDeleteMessage(state) {
            return state.deleteMessage
        },
        getProgramNoFilter(state) {
            const program = _.forEach(state.program, item => {
                const date = item.time.split('–')
                item.timeStart = date[0].trim()
                item.timeEnd = date[1].trim()
            })
            return program
        },
        getProgram(state) {
            if (state.program) {
                let arr = []
                for (let i in state.program) {
                    const program = state.program[i]
                    const date = program.time.split('-')
                    if (date.length > 1) {
                        program.timeStart = date[0].trim()
                        program.timeEnd = date[1].trim()
                    }
                }
                const programSort = _.sortBy(state.program, item => {
                    return moment(item.timeStart, 'HH-mm').tz('Europe/Moscow').format('YYYY-MM-DD HH:mm')
                })
                for (let program of programSort) {
                    let findDay = _.findIndex(arr, item => item.id === program.day_id)
                    if (findDay === -1) {
                        arr.push({
                            id: program.day_id,
                            name: program.programDay.name,
                            sections: []
                        })
                        findDay = arr.length - 1
                    }
                    let findSection = _.findIndex(arr[findDay].sections, item => {
                        return item.id === program.section_id
                    })
                    if (findSection === -1) {
                        arr[findDay].sections.push({
                            id: program.section_id,
                            name: program.programSection.name,
                            theme: program.programSection.theme,
                            order: program.programSection.order,
                            program: []
                        })
                        findSection = arr[findDay].sections.length - 1
                    }
                    const contentLength = program.content.replace(/(<[^>]+>|<[^>]>|<\/[^>]>)/g, '').length
                    arr[findDay].sections[findSection].program.push({
                        content: contentLength > 1 ? program.content : '',
                        order: program.order,
                        name: program.name,
                        time: program.time,
                        id: program.id,
                        speaker: program.Speaker,
                        dayId: program.day_id,
                        sectionId: program.section_id,
                        timeStart: program.timeStart,
                        timeEnd: program.timeEnd,
                        presentation: program.presentation
                    })
                }
                for (let i in arr) {
                    arr[i].sections = _.sortBy(arr[i].sections, ['order'])
                }
                return arr
            }
            return []
        },
        isAuth(state) {
            return !!state.user
        },
        getRightModal(state) {
            return state.rightModal
        },
        getEvent(state) {
            return state.event || {}
        },
        getSettings(state) {
            return state.settings
        },
        getSettingByValue(state) {
            return (name) => {
                const find = _.find(state.settings, item => item.name === name)
                if (find) {
                    return find.value
                } else {
                    return undefined
                }
            }
        },
        getContent(state) {
            return state.content
        },
        getContentByType(state) {
            return (type) => {
                if (state.content) {
                    let items = []
                    for (let item of state.content) {
                        if (item.type === type) {
                            items.push(item)
                        }
                    }
                    items = _.orderBy(items, ['order'])
                    return items
                } else {
                    return null
                }
            }
        }
    },
    mutations: {
        changeRightModal(state, payload) {
            return state.rightModal = payload
        },
        changeDeleteMessage(state, payload) {
            return state.deleteMessage = payload
        },
        changeUser(state, payload) {
            return state.user = payload
        },
        changeEvent(state, payload) {
            return state.event = payload
        },
        changeSettings(state, payload) {
            return state.settings = payload
        },
        changeContent(state, payload) {
            return state.content = payload
        },
        changeProgram(state, payload) {
            return state.program = payload
        }
    },
    actions: {
        loadEvent() {
            // axios.get('/event')
            //     .then(resp => {
            //         commit('changeEvent', resp.data)
            //     })
            //     .catch(e => {
            //         console.log(e)
            //     })
        },
        loadContent() {
            // axios.get('/content')
            //     .then(resp => {
            //         commit('changeContent', resp.data)
            //     })
            //     .catch(e => {
            //         console.log(e)
            //     })
        },
        loadSettings({commit}) {
            // axios.get('/setting/client')
            //     .then(resp => {
            commit('changeSettings', [{
                "id": 2,
                "name": "name_first",
                "nameRus": "Имя",
                "value": "Анна Баканович",
                "type": "client",
                "createdAt": "2023-03-14T17:07:10.000Z",
                "updatedAt": "2023-03-14T17:07:10.000Z"
            }, {
                "id": 3,
                "name": "phone_first",
                "nameRus": "Телефон",
                "value": "+7 495 369 48 16 доб. 606",
                "type": "client",
                "createdAt": "2023-03-14T17:07:40.000Z",
                "updatedAt": "2023-03-14T17:07:40.000Z"
            }, {
                "id": 4,
                "name": "email_first",
                "nameRus": "Email",
                "value": "abakanovich@astralinux.ru",
                "type": "client",
                "createdAt": "2023-03-14T17:08:16.000Z",
                "updatedAt": "2023-03-14T17:08:16.000Z"
            }, {
                "id": 5,
                "name": "name_second",
                "nameRus": "Имя",
                "value": "Ольга Яшенкова",
                "type": "client",
                "createdAt": "2023-03-14T17:08:58.000Z",
                "updatedAt": "2023-03-14T17:08:58.000Z"
            }, {
                "id": 6,
                "name": "phone_second",
                "nameRus": "Телефон",
                "value": "+7 495 369 48 16 доб. 602",
                "type": "client",
                "createdAt": "2023-03-14T17:09:22.000Z",
                "updatedAt": "2023-03-14T17:09:22.000Z"
            }, {
                "id": 7,
                "name": "email_second",
                "nameRus": "Email",
                "value": "oyashenkova@astralinux.ru",
                "type": "client",
                "createdAt": "2023-03-14T17:09:52.000Z",
                "updatedAt": "2023-03-14T17:09:52.000Z"
            }, {
                "id": 8,
                "name": "social_twitter",
                "nameRus": "Твиттер",
                "value": "https://twitter.com/astralinuxos/",
                "type": "client",
                "createdAt": "2023-03-15T17:10:36.000Z",
                "updatedAt": "2023-03-15T17:10:36.000Z"
            }, {
                "id": 9,
                "name": "social_youtube",
                "nameRus": "YouTube",
                "value": "https://www.youtube.com/channel/UCCx_04wNNOyqP425rH0PboA",
                "type": "client",
                "createdAt": "2023-03-15T17:11:45.000Z",
                "updatedAt": "2023-03-15T17:11:45.000Z"
            }, {
                "id": 10,
                "name": "social_vk",
                "nameRus": "ВК",
                "value": "https://vk.com/astralinux",
                "type": "client",
                "createdAt": "2023-03-15T17:12:12.000Z",
                "updatedAt": "2023-03-15T17:12:12.000Z"
            }, {
                "id": 11,
                "name": "social_tg",
                "nameRus": "ТГ",
                "value": "https://t.me/astralinux",
                "type": "client",
                "createdAt": "2023-03-15T17:12:54.000Z",
                "updatedAt": "2023-03-15T17:12:54.000Z"
            }, {
                "id": 12,
                "name": "metrika",
                "nameRus": "Ключ для метрики",
                "value": "92966840",
                "type": "client",
                "createdAt": "2023-03-27T10:20:13.000Z",
                "updatedAt": "2023-03-27T10:20:13.000Z"
            }])
            // })
            // .catch(e => {
            //     console.log(e)
            // })
        },
        loadProgram({commit}) {
            // axios.get('/program')
            //     .then(resp => {
            commit(
                'changeProgram',
                [
                    {
                        "id": 56,
                        "name": "<p>Вступительное слово</p>",
                        "time": "12:40 – 12:45",
                        "content": "",
                        "order": 1,
                        "presentation": null,
                        "createdAt": "2023-04-13T12:54:57.000Z",
                        "updatedAt": "2023-04-13T12:54:57.000Z",
                        "section_id": 5,
                        "day_id": 1,
                        "programDay": {
                            "id": 1,
                            "name": "День 1",
                            "createdAt": "2023-03-14T20:42:53.000Z",
                            "updatedAt": "2023-03-14T20:42:53.000Z"
                        },
                        "programSection": {
                            "id": 5,
                            "name": "Секция 4",
                            "theme": "Экосистема решений и практика применения",
                            "order": 5,
                            "createdAt": "2023-03-15T17:21:13.000Z",
                            "updatedAt": "2023-03-15T17:21:13.000Z"
                        },
                        "Speaker": [{
                            "id": 67,
                            "name": "Соболев Александр - модератор",
                            "job": "Руководитель отдела пресейла дирекции внедрения и сопровождения ГК «Астра»",
                            "avatar": "/images/Соболев Александр.jpg",
                            "info": "",
                            "createdAt": "2023-04-13T12:54:15.000Z",
                            "updatedAt": "2023-04-24T09:38:19.000Z",
                            "program_speakers": {
                                "createdAt": "2023-04-13T12:54:57.000Z",
                                "updatedAt": "2023-04-13T12:54:57.000Z",
                                "program_id": 56,
                                "speaker_id": 67
                            }
                        }]
                    },
                    {
                        "id": 55,
                        "name": "<p>Вступительное слово</p>",
                        "time": "12:40 – 12:45",
                        "content": "",
                        "order": 1,
                        "presentation": null,
                        "createdAt": "2023-04-13T12:49:28.000Z",
                        "updatedAt": "2023-04-13T12:49:54.000Z",
                        "section_id": 4,
                        "day_id": 1,
                        "programDay": {
                            "id": 1,
                            "name": "День 1",
                            "createdAt": "2023-03-14T20:42:53.000Z",
                            "updatedAt": "2023-03-14T20:42:53.000Z"
                        },
                        "programSection": {
                            "id": 4,
                            "name": "Секция 3",
                            "theme": "Экосистема решений и практика применения ",
                            "order": 4,
                            "createdAt": "2023-03-14T20:46:22.000Z",
                            "updatedAt": "2023-04-10T09:54:42.000Z"
                        },
                        "Speaker": [{
                            "id": 65,
                            "name": "Синьков Кирилл - модератор ",
                            "job": "Руководитель направления по работе с технологическими партнерами ГК «Астра»",
                            "avatar": "/images/синьков.jpg",
                            "info": "",
                            "createdAt": "2023-04-13T12:41:30.000Z",
                            "updatedAt": "2023-04-24T09:33:30.000Z",
                            "program_speakers": {
                                "createdAt": "2023-04-13T12:49:28.000Z",
                                "updatedAt": "2023-04-13T12:49:28.000Z",
                                "program_id": 55,
                                "speaker_id": 65
                            }
                        }]
                    }, {
                    "id": 52,
                    "name": "<p>Вступительное слово</p>",
                    "time": "12:40 – 12:45",
                    "content": "",
                    "order": 1,
                    "presentation": null,
                    "createdAt": "2023-04-13T12:37:02.000Z",
                    "updatedAt": "2023-04-23T09:18:22.000Z",
                    "section_id": 3,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 3,
                        "name": "Секция 2",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 3,
                        "createdAt": "2023-03-14T20:45:11.000Z",
                        "updatedAt": "2023-04-10T09:54:43.000Z"
                    },
                    "Speaker": [{
                        "id": 64,
                        "name": "Мылицын Роман - модератор ",
                        "job": "Руководитель направления перспективных исследований ГК  «Астра»",
                        "avatar": "/images/Мылицын Роман.jpg",
                        "info": "",
                        "createdAt": "2023-04-13T12:36:20.000Z",
                        "updatedAt": "2023-04-13T12:36:20.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-13T12:37:03.000Z",
                            "updatedAt": "2023-04-13T12:37:03.000Z",
                            "program_id": 52,
                            "speaker_id": 64
                        }
                    }]
                }, {
                    "id": 49,
                    "name": "<p>Вступительное слово</p>",
                    "time": "12:40 – 12:45",
                    "content": "",
                    "order": 1,
                    "presentation": null,
                    "createdAt": "2023-04-13T12:22:34.000Z",
                    "updatedAt": "2023-04-13T12:22:34.000Z",
                    "section_id": 2,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 2,
                        "name": "Секция 1",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 2,
                        "createdAt": "2023-03-14T20:44:31.000Z",
                        "updatedAt": "2023-04-24T08:39:17.000Z"
                    },
                    "Speaker": [{
                        "id": 59,
                        "name": "Спирькова Анастасия - модератор",
                        "job": "Заместитель директора по маркетингу ГК «Астра»",
                        "avatar": "/images/Спирькова Анастасия_Астра.jpg",
                        "info": "",
                        "createdAt": "2023-04-13T12:21:11.000Z",
                        "updatedAt": "2023-04-13T12:21:11.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-13T12:22:34.000Z",
                            "updatedAt": "2023-04-13T12:22:34.000Z",
                            "program_id": 49,
                            "speaker_id": 59
                        }
                    }]
                }, {
                    "id": 13,
                    "name": "<p>Вступительное слово</p>",
                    "time": "10:00 – 10:05",
                    "content": "",
                    "order": 1,
                    "presentation": "https://disk.yandex.ru/i/Xua8j8ZZclsYig",
                    "createdAt": "2023-04-06T07:59:37.000Z",
                    "updatedAt": "2023-05-23T16:54:33.000Z",
                    "section_id": 1,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 1,
                        "name": "Пленарная секция",
                        "theme": "Трансформация рынка и бизнеса",
                        "order": 1,
                        "createdAt": "2023-03-14T20:43:45.000Z",
                        "updatedAt": "2023-04-10T09:54:45.000Z"
                    },
                    "Speaker": [{
                        "id": 17,
                        "name": "Гутин Александр",
                        "job": "Директор по маркетингу ГК «Астра»",
                        "avatar": "/images/Модератор_Гутин Александр (1).jpg",
                        "info": "",
                        "createdAt": "2023-04-06T07:35:28.000Z",
                        "updatedAt": "2023-04-11T15:17:24.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-06T07:59:37.000Z",
                            "updatedAt": "2023-04-06T07:59:37.000Z",
                            "program_id": 13,
                            "speaker_id": 17
                        }
                    }]
                }, {
                    "id": 1,
                    "name": "<p>ГК «Астра»: движение в вашу сторону</p>",
                    "time": "10:05 – 10:20",
                    "content": "",
                    "order": 2,
                    "presentation": "https://disk.yandex.ru/i/O2ROricZoZ8oqQ",
                    "createdAt": "2023-03-14T20:50:28.000Z",
                    "updatedAt": "2023-05-23T16:54:48.000Z",
                    "section_id": 1,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 1,
                        "name": "Пленарная секция",
                        "theme": "Трансформация рынка и бизнеса",
                        "order": 1,
                        "createdAt": "2023-03-14T20:43:45.000Z",
                        "updatedAt": "2023-04-10T09:54:45.000Z"
                    },
                    "Speaker": [{
                        "id": 15,
                        "name": "Сивцев Илья",
                        "job": "Генеральный директор ГК «Астра»",
                        "avatar": "/images/Сивцев Илья_Астра.JPG",
                        "info": "",
                        "createdAt": "2023-03-24T10:05:14.000Z",
                        "updatedAt": "2023-03-24T10:05:14.000Z",
                        "program_speakers": {
                            "createdAt": "2023-03-24T10:05:44.000Z",
                            "updatedAt": "2023-03-24T10:05:44.000Z",
                            "program_id": 1,
                            "speaker_id": 15
                        }
                    }]
                }, {
                    "id": 41,
                    "name": "<p>DCImanager — российская платформа для управления физической ИТ-инфраструктурой</p>",
                    "time": "12:45 – 13:15",
                    "content": "",
                    "order": 2,
                    "presentation": "https://disk.yandex.ru/i/pXaeW2Y_fLDCug",
                    "createdAt": "2023-04-07T07:34:41.000Z",
                    "updatedAt": "2023-05-23T17:10:15.000Z",
                    "section_id": 5,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 5,
                        "name": "Секция 4",
                        "theme": "Экосистема решений и практика применения",
                        "order": 5,
                        "createdAt": "2023-03-15T17:21:13.000Z",
                        "updatedAt": "2023-03-15T17:21:13.000Z"
                    },
                    "Speaker": [{
                        "id": 42,
                        "name": "Царева Наталья",
                        "job": "Директор по продуктам ISPsystem ",
                        "avatar": "/images/Царева Наталья.jpg",
                        "info": "",
                        "createdAt": "2023-04-07T07:23:08.000Z",
                        "updatedAt": "2023-04-11T07:51:39.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-07T07:34:41.000Z",
                            "updatedAt": "2023-04-07T07:34:41.000Z",
                            "program_id": 41,
                            "speaker_id": 42
                        }
                    }]
                }, {
                    "id": 17,
                    "name": "<p>Astra Server - флагманский продукт ГК «Астра»</p>",
                    "time": "12:45 – 13:15",
                    "content": "",
                    "order": 2,
                    "presentation": "https://disk.yandex.ru/i/h3VEO2gqCCMJ3g",
                    "createdAt": "2023-04-06T08:08:07.000Z",
                    "updatedAt": "2023-05-23T16:57:35.000Z",
                    "section_id": 2,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 2,
                        "name": "Секция 1",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 2,
                        "createdAt": "2023-03-14T20:44:31.000Z",
                        "updatedAt": "2023-04-24T08:39:17.000Z"
                    },
                    "Speaker": [{
                        "id": 22,
                        "name": "Тюрин Алексей",
                        "job": "Заместитель руководителя  дирекции внедрения и сопровождения ГК «Астра»",
                        "avatar": "/images/Тюрин Алексей_Астра.jpg",
                        "info": "",
                        "createdAt": "2023-04-06T07:37:16.000Z",
                        "updatedAt": "2023-04-11T15:17:50.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-06T08:08:07.000Z",
                            "updatedAt": "2023-04-06T08:08:07.000Z",
                            "program_id": 17,
                            "speaker_id": 22
                        }
                    }]
                }, {
                    "id": 34,
                    "name": "<p>СУБД Tantor. Что внутри?</p>",
                    "time": "12:45 – 13:15",
                    "content": "",
                    "order": 2,
                    "presentation": "https://disk.yandex.ru/i/ZZKiPPeGQOycSQ",
                    "createdAt": "2023-04-07T07:20:59.000Z",
                    "updatedAt": "2023-05-23T17:06:12.000Z",
                    "section_id": 4,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 4,
                        "name": "Секция 3",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 4,
                        "createdAt": "2023-03-14T20:46:22.000Z",
                        "updatedAt": "2023-04-10T09:54:42.000Z"
                    },
                    "Speaker": [{
                        "id": 28,
                        "name": "Яценко Вадим",
                        "job": "Генеральный директор «Тантор Лабс», ГК «Астра»",
                        "avatar": "/images/Яценко Вадим_Тантор Лабс.JPG",
                        "info": "",
                        "createdAt": "2023-04-07T06:55:32.000Z",
                        "updatedAt": "2023-04-24T09:04:10.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-07T07:20:59.000Z",
                            "updatedAt": "2023-04-07T07:20:59.000Z",
                            "program_id": 34,
                            "speaker_id": 28
                        }
                    }]
                }, {
                    "id": 20,
                    "name": "<p>Импортозамещение всего: опыт внедрения решений экосистемы «Астра»</p>",
                    "time": "12:45 – 13:15",
                    "content": "",
                    "order": 2,
                    "presentation": "https://disk.yandex.ru/i/2Luz9e9KH8ni7Q",
                    "createdAt": "2023-04-06T08:12:19.000Z",
                    "updatedAt": "2023-05-23T17:03:53.000Z",
                    "section_id": 3,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 3,
                        "name": "Секция 2",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 3,
                        "createdAt": "2023-03-14T20:45:11.000Z",
                        "updatedAt": "2023-04-10T09:54:43.000Z"
                    },
                    "Speaker": [{
                        "id": 25,
                        "name": "Мурунов Денис",
                        "job": "Руководитель практики построения ИТ-инфраструктуры К2Тех",
                        "avatar": "/images/Денис Мурунов_К2Тех_2.jpeg",
                        "info": "",
                        "createdAt": "2023-04-06T07:38:59.000Z",
                        "updatedAt": "2023-04-06T07:50:49.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-06T08:12:19.000Z",
                            "updatedAt": "2023-04-06T08:12:19.000Z",
                            "program_id": 20,
                            "speaker_id": 25
                        }
                    }]
                }, {
                    "id": 3,
                    "name": "<p>Импортозамещение: от слов к делу. Опыт первопроходцев</p>",
                    "time": "10:20 – 10:50",
                    "content": "",
                    "order": 3,
                    "presentation": null,
                    "createdAt": "2023-03-15T17:24:31.000Z",
                    "updatedAt": "2023-04-06T08:00:27.000Z",
                    "section_id": 1,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 1,
                        "name": "Пленарная секция",
                        "theme": "Трансформация рынка и бизнеса",
                        "order": 1,
                        "createdAt": "2023-03-14T20:43:45.000Z",
                        "updatedAt": "2023-04-10T09:54:45.000Z"
                    },
                    "Speaker": [{
                        "id": 14,
                        "name": "Шальнов Олег",
                        "job": "Директор департамента управления ИТ-проектами и интеграцией Концерна «Росэнергоатом»",
                        "avatar": "/images/Шальнов Олег_Росэнергоатом.jpg",
                        "info": "",
                        "createdAt": "2023-03-24T10:00:44.000Z",
                        "updatedAt": "2023-04-24T08:53:24.000Z",
                        "program_speakers": {
                            "createdAt": "2023-03-24T10:01:20.000Z",
                            "updatedAt": "2023-03-24T10:01:20.000Z",
                            "program_id": 3,
                            "speaker_id": 14
                        }
                    }]
                }, {
                    "id": 42,
                    "name": "<p>BILLmanager 6 — российская платформа для автоматизации управления частным и гибридным облаком</p>",
                    "time": "13:15 – 13:45",
                    "content": "",
                    "order": 3,
                    "presentation": "https://disk.yandex.ru/i/rHpPXqaCs984iA",
                    "createdAt": "2023-04-07T07:35:33.000Z",
                    "updatedAt": "2023-05-23T17:10:31.000Z",
                    "section_id": 5,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 5,
                        "name": "Секция 4",
                        "theme": "Экосистема решений и практика применения",
                        "order": 5,
                        "createdAt": "2023-03-15T17:21:13.000Z",
                        "updatedAt": "2023-03-15T17:21:13.000Z"
                    },
                    "Speaker": [{
                        "id": 43,
                        "name": "Михайлов Игорь",
                        "job": "Менеджер по продукту ISPsystem ",
                        "avatar": "/images/Михайлов Игорь.jpg",
                        "info": "",
                        "createdAt": "2023-04-07T07:23:24.000Z",
                        "updatedAt": "2023-04-12T15:27:45.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-07T07:35:33.000Z",
                            "updatedAt": "2023-04-07T07:35:33.000Z",
                            "program_id": 42,
                            "speaker_id": 43
                        }
                    }]
                }, {
                    "id": 35,
                    "name": "<p>RuBackup 2.0: что нового?</p>",
                    "time": "13:15 – 13:45",
                    "content": "",
                    "order": 3,
                    "presentation": "https://disk.yandex.ru/i/tKRFSLjIykCR-w",
                    "createdAt": "2023-04-07T07:27:50.000Z",
                    "updatedAt": "2023-05-23T17:07:41.000Z",
                    "section_id": 4,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 4,
                        "name": "Секция 3",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 4,
                        "createdAt": "2023-03-14T20:46:22.000Z",
                        "updatedAt": "2023-04-10T09:54:42.000Z"
                    },
                    "Speaker": [{
                        "id": 37,
                        "name": "Маслов Федор ",
                        "job": "Менеджер продукта «Рубэкап»,  ГК «Астра»",
                        "avatar": "/images/Маслов Федор_Rubackup.jpg",
                        "info": "",
                        "createdAt": "2023-04-07T07:21:32.000Z",
                        "updatedAt": "2023-04-11T18:03:49.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-07T07:27:50.000Z",
                            "updatedAt": "2023-04-07T07:27:50.000Z",
                            "program_id": 35,
                            "speaker_id": 37
                        }
                    }, {
                        "id": 36,
                        "name": "Кузнецов Андрей",
                        "job": "Генеральный директор «Рубэкап», ГК «Астра»",
                        "avatar": "/images/Кузнецов Андрей_RuBackup.jpg",
                        "info": "",
                        "createdAt": "2023-04-07T07:21:18.000Z",
                        "updatedAt": "2023-04-11T18:03:20.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-07T07:27:50.000Z",
                            "updatedAt": "2023-04-07T07:27:50.000Z",
                            "program_id": 35,
                            "speaker_id": 36
                        }
                    }]
                }, {
                    "id": 27,
                    "name": "<p>Программно-аппаратный комплекс VDI Delta Computers и Astra Linux: безопасная доверенная среда для пользователей</p>",
                    "time": "13:15 – 13:45",
                    "content": "",
                    "order": 3,
                    "presentation": null,
                    "createdAt": "2023-04-07T07:01:35.000Z",
                    "updatedAt": "2023-04-23T09:18:44.000Z",
                    "section_id": 3,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 3,
                        "name": "Секция 2",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 3,
                        "createdAt": "2023-03-14T20:45:11.000Z",
                        "updatedAt": "2023-04-10T09:54:43.000Z"
                    },
                    "Speaker": [{
                        "id": 74,
                        "name": "Терещенко Максим  ",
                        "job": "Руководитель департамента управления проектами Delta Computers ",
                        "avatar": "/images/Терещенко Максим.jpg",
                        "info": "",
                        "createdAt": "2023-04-17T16:41:33.000Z",
                        "updatedAt": "2023-04-17T16:41:33.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-17T16:42:26.000Z",
                            "updatedAt": "2023-04-17T16:42:26.000Z",
                            "program_id": 27,
                            "speaker_id": 74
                        }
                    }]
                }, {
                    "id": 18,
                    "name": "<p>Astra Automation: вся экосистема в один клик</p>",
                    "time": "13:15 – 13:45",
                    "content": "",
                    "order": 3,
                    "presentation": "https://disk.yandex.ru/i/F69jCy_JRC6ldQ",
                    "createdAt": "2023-04-06T08:08:49.000Z",
                    "updatedAt": "2023-05-23T16:58:45.000Z",
                    "section_id": 2,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 2,
                        "name": "Секция 1",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 2,
                        "createdAt": "2023-03-14T20:44:31.000Z",
                        "updatedAt": "2023-04-24T08:39:17.000Z"
                    },
                    "Speaker": [{
                        "id": 23,
                        "name": "Добрынин Кирилл",
                        "job": "Директор департамента разработки средств автоматизации ГК «Астра»",
                        "avatar": "/images/Добрынин.jpg",
                        "info": "",
                        "createdAt": "2023-04-06T07:37:36.000Z",
                        "updatedAt": "2023-04-24T08:56:15.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-06T08:08:49.000Z",
                            "updatedAt": "2023-04-06T08:08:49.000Z",
                            "program_id": 18,
                            "speaker_id": 23
                        }
                    }]
                }, {
                    "id": 43,
                    "name": "<p>Импортозамещение в области интеллектуальной обработки документов</p>",
                    "time": "13:45 – 14:15",
                    "content": "",
                    "order": 4,
                    "presentation": "https://disk.yandex.ru/i/JUQwq9gkhXppCA",
                    "createdAt": "2023-04-07T07:39:49.000Z",
                    "updatedAt": "2023-05-23T17:11:01.000Z",
                    "section_id": 5,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 5,
                        "name": "Секция 4",
                        "theme": "Экосистема решений и практика применения",
                        "order": 5,
                        "createdAt": "2023-03-15T17:21:13.000Z",
                        "updatedAt": "2023-03-15T17:21:13.000Z"
                    },
                    "Speaker": [{
                        "id": 57,
                        "name": "Сажин Олег",
                        "job": "Советник генерального директора Content AI",
                        "avatar": "/images/Сажин Олег.jpg",
                        "info": "",
                        "createdAt": "2023-04-11T18:08:33.000Z",
                        "updatedAt": "2023-04-11T18:15:41.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-11T18:08:52.000Z",
                            "updatedAt": "2023-04-11T18:08:52.000Z",
                            "program_id": 43,
                            "speaker_id": 57
                        }
                    }]
                }, {
                    "id": 19,
                    "name": "<p>Переход ГК «Газпром нефть» на ОС Astra Linux</p>",
                    "time": "13:45 – 14:15",
                    "content": "",
                    "order": 4,
                    "presentation": null,
                    "createdAt": "2023-04-06T08:09:44.000Z",
                    "updatedAt": "2023-04-13T08:55:12.000Z",
                    "section_id": 2,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 2,
                        "name": "Секция 1",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 2,
                        "createdAt": "2023-03-14T20:44:31.000Z",
                        "updatedAt": "2023-04-24T08:39:17.000Z"
                    },
                    "Speaker": [{
                        "id": 24,
                        "name": "Иванов Алексей ",
                        "job": "Руководитель направления, управление интеграционных и платформенных решений ПАО «Газпром нефть» ",
                        "avatar": "/images/Иванов Алексей_Газпром нефть.JPG",
                        "info": "",
                        "createdAt": "2023-04-06T07:37:51.000Z",
                        "updatedAt": "2023-04-24T08:57:23.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-06T08:09:44.000Z",
                            "updatedAt": "2023-04-06T08:09:44.000Z",
                            "program_id": 19,
                            "speaker_id": 24
                        }
                    }]
                }, {
                    "id": 28,
                    "name": "<p>VMmanager 6 — российская масштабируемая платформа виртуализации</p>",
                    "time": "13:45 – 14:15",
                    "content": "",
                    "order": 4,
                    "presentation": "https://disk.yandex.ru/i/3pdg0roP2VobVA",
                    "createdAt": "2023-04-07T07:03:31.000Z",
                    "updatedAt": "2023-05-23T17:04:14.000Z",
                    "section_id": 3,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 3,
                        "name": "Секция 2",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 3,
                        "createdAt": "2023-03-14T20:45:11.000Z",
                        "updatedAt": "2023-04-10T09:54:43.000Z"
                    },
                    "Speaker": [{
                        "id": 31,
                        "name": "Абанкин Олег ",
                        "job": "Менеджер по продукту ISPsystem ",
                        "avatar": "/images/Абанкин Олег.png",
                        "info": "",
                        "createdAt": "2023-04-07T07:02:49.000Z",
                        "updatedAt": "2023-04-12T15:30:03.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-07T07:03:31.000Z",
                            "updatedAt": "2023-04-07T07:03:31.000Z",
                            "program_id": 28,
                            "speaker_id": 31
                        }
                    }]
                }, {
                    "id": 14,
                    "name": "<p>ICL Техно - российский производитель вычислительной техники</p>",
                    "time": "10:50 – 11:05",
                    "content": "",
                    "order": 4,
                    "presentation": "https://disk.yandex.ru/i/UWTTGnULIBY8Cg",
                    "createdAt": "2023-04-06T08:01:40.000Z",
                    "updatedAt": "2023-05-23T16:55:35.000Z",
                    "section_id": 1,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 1,
                        "name": "Пленарная секция",
                        "theme": "Трансформация рынка и бизнеса",
                        "order": 1,
                        "createdAt": "2023-03-14T20:43:45.000Z",
                        "updatedAt": "2023-04-10T09:54:45.000Z"
                    },
                    "Speaker": [{
                        "id": 52,
                        "name": "Чулков Антон ",
                        "job": "Директор по работе с партнерами ICL Техно",
                        "avatar": "/images/Чулков Антон Сергееви_ICLТехно.jpg",
                        "info": "",
                        "createdAt": "2023-04-11T07:35:57.000Z",
                        "updatedAt": "2023-04-20T11:59:46.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-11T07:36:28.000Z",
                            "updatedAt": "2023-04-11T07:36:28.000Z",
                            "program_id": 14,
                            "speaker_id": 52
                        }
                    }]
                }, {
                    "id": 36,
                    "name": "<p>Экосистема IVA UC: навстречу глобальным вызовам</p>",
                    "time": "13:45 – 14:15",
                    "content": "",
                    "order": 4,
                    "presentation": "https://disk.yandex.ru/i/6oMINXsEoiPR6Q",
                    "createdAt": "2023-04-07T07:28:27.000Z",
                    "updatedAt": "2023-05-23T17:07:57.000Z",
                    "section_id": 4,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 4,
                        "name": "Секция 3",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 4,
                        "createdAt": "2023-03-14T20:46:22.000Z",
                        "updatedAt": "2023-04-10T09:54:42.000Z"
                    },
                    "Speaker": [{
                        "id": 66,
                        "name": "Смирнов Максим ",
                        "job": "Заместитель генерального директора IVA Technologies",
                        "avatar": "/images/Смирнов Максим.jpg",
                        "info": "",
                        "createdAt": "2023-04-13T12:41:55.000Z",
                        "updatedAt": "2023-04-13T12:41:55.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-13T12:45:41.000Z",
                            "updatedAt": "2023-04-13T12:45:41.000Z",
                            "program_id": 36,
                            "speaker_id": 66
                        }
                    }]
                }, {
                    "id": 44,
                    "name": "<p>ALD PRO: домен и управление объектами</p>",
                    "time": "14:15 – 14:45",
                    "content": "",
                    "order": 5,
                    "presentation": "https://disk.yandex.ru/i/WjDLG_g9Z1jR7w",
                    "createdAt": "2023-04-07T07:40:23.000Z",
                    "updatedAt": "2023-05-23T17:11:20.000Z",
                    "section_id": 5,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 5,
                        "name": "Секция 4",
                        "theme": "Экосистема решений и практика применения",
                        "order": 5,
                        "createdAt": "2023-03-15T17:21:13.000Z",
                        "updatedAt": "2023-03-15T17:21:13.000Z"
                    },
                    "Speaker": [{
                        "id": 45,
                        "name": "Фоменко Алексей",
                        "job": "Директор департамента разработки средств управления ГК «Астра»",
                        "avatar": "/images/фоменк.jpg",
                        "info": "",
                        "createdAt": "2023-04-07T07:25:25.000Z",
                        "updatedAt": "2023-04-11T15:19:09.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-25T05:41:00.000Z",
                            "updatedAt": "2023-04-25T05:41:00.000Z",
                            "program_id": 44,
                            "speaker_id": 45
                        }
                    }, {
                        "id": 46,
                        "name": "Паутов Евгений",
                        "job": "Заместитель директора департамента разработки средств управления ГК «Астра»",
                        "avatar": "/images/Паутов Евгений 3.jpg",
                        "info": "",
                        "createdAt": "2023-04-07T07:26:10.000Z",
                        "updatedAt": "2023-04-11T07:55:29.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-07T07:40:23.000Z",
                            "updatedAt": "2023-04-07T07:40:23.000Z",
                            "program_id": 44,
                            "speaker_id": 46
                        }
                    }]
                }, {
                    "id": 59,
                    "name": "<p>Программный комплекс средств виртуализации «БРЕСТ»: позиционирование и планы развития</p>",
                    "time": "14:15 – 14:45",
                    "content": "",
                    "order": 5,
                    "presentation": "https://disk.yandex.ru/i/-vHCsaraZ8nJ4w",
                    "createdAt": "2023-04-17T16:45:20.000Z",
                    "updatedAt": "2023-05-23T17:04:30.000Z",
                    "section_id": 3,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 3,
                        "name": "Секция 2",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 3,
                        "createdAt": "2023-03-14T20:45:11.000Z",
                        "updatedAt": "2023-04-10T09:54:43.000Z"
                    },
                    "Speaker": [{
                        "id": 30,
                        "name": "Мухин Денис",
                        "job": "Директор департамента виртуализации и облачных сервисов ГК «Астра»",
                        "avatar": "/images/Мухин Денис.jpg",
                        "info": "",
                        "createdAt": "2023-04-07T07:00:45.000Z",
                        "updatedAt": "2023-04-11T15:18:14.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-17T16:45:20.000Z",
                            "updatedAt": "2023-04-17T16:45:20.000Z",
                            "program_id": 59,
                            "speaker_id": 30
                        }
                    }]
                }, {
                    "id": 12,
                    "name": "<p>Импортозамещение с ПАК Практик</p>",
                    "time": "14:15 – 14:45",
                    "content": "",
                    "order": 5,
                    "presentation": "https://disk.yandex.ru/i/_E6RKKqNEulm7A",
                    "createdAt": "2023-03-31T15:24:39.000Z",
                    "updatedAt": "2023-05-23T17:08:34.000Z",
                    "section_id": 4,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 4,
                        "name": "Секция 3",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 4,
                        "createdAt": "2023-03-14T20:46:22.000Z",
                        "updatedAt": "2023-04-10T09:54:42.000Z"
                    },
                    "Speaker": [{
                        "id": 16,
                        "name": "Бочкарев Владимир ",
                        "job": "Технический директор TEGRUS ",
                        "avatar": "/images/Бочкарев Владимир.jpeg",
                        "info": "",
                        "createdAt": "2023-03-31T15:22:39.000Z",
                        "updatedAt": "2023-03-31T15:22:39.000Z",
                        "program_speakers": {
                            "createdAt": "2023-03-31T15:24:39.000Z",
                            "updatedAt": "2023-03-31T15:24:39.000Z",
                            "program_id": 12,
                            "speaker_id": 16
                        }
                    }]
                }, {
                    "id": 5,
                    "name": "<p>Возможности и функции экосистемы продуктов ViPNet для использования вместе с ОС Astra Linux</p>",
                    "time": "14:15 – 14:45",
                    "content": "",
                    "order": 5,
                    "presentation": "https://disk.yandex.ru/i/6Pj-yFrhnIYRoA",
                    "createdAt": "2023-03-15T17:32:34.000Z",
                    "updatedAt": "2023-05-23T16:59:28.000Z",
                    "section_id": 2,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 2,
                        "name": "Секция 1",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 2,
                        "createdAt": "2023-03-14T20:44:31.000Z",
                        "updatedAt": "2023-04-24T08:39:17.000Z"
                    },
                    "Speaker": [{
                        "id": 13,
                        "name": "Василенков Александр ",
                        "job": "Руководитель продуктового направления «ИнфоТеКС» ",
                        "avatar": "/images/Василенков Александр_ИнфоТекс.jpg",
                        "info": "",
                        "createdAt": "2023-03-24T09:56:29.000Z",
                        "updatedAt": "2023-04-24T08:56:49.000Z",
                        "program_speakers": {
                            "createdAt": "2023-03-24T09:58:00.000Z",
                            "updatedAt": "2023-03-24T09:58:00.000Z",
                            "program_id": 5,
                            "speaker_id": 13
                        }
                    }]
                }, {
                    "id": 15,
                    "name": "<p>Опыт перевода на платформу Linux наукоемкого ПО для нефтегазодобычи</p>",
                    "time": "11:05 – 11:20",
                    "content": "",
                    "order": 5,
                    "presentation": null,
                    "createdAt": "2023-04-06T08:03:03.000Z",
                    "updatedAt": "2023-04-20T12:01:33.000Z",
                    "section_id": 1,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 1,
                        "name": "Пленарная секция",
                        "theme": "Трансформация рынка и бизнеса",
                        "order": 1,
                        "createdAt": "2023-03-14T20:43:45.000Z",
                        "updatedAt": "2023-04-10T09:54:45.000Z"
                    },
                    "Speaker": [{
                        "id": 19,
                        "name": "Борщук Олег",
                        "job": "Начальник управления разработки ПО для моделирования  центра разработки наукоемкого программного обеспечения ПАО «НК «Роснефть» (ООО «РН-БашНИПИнефть»)",
                        "avatar": "/images/Борщук Олег_Роснефть.jpg",
                        "info": "",
                        "createdAt": "2023-04-06T07:36:15.000Z",
                        "updatedAt": "2023-04-11T07:32:11.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-06T08:03:03.000Z",
                            "updatedAt": "2023-04-06T08:03:03.000Z",
                            "program_id": 15,
                            "speaker_id": 19
                        }
                    }]
                }, {
                    "id": 45,
                    "name": "<p>Перерыв</p>",
                    "time": "14:45 – 15:30",
                    "content": "",
                    "order": 6,
                    "presentation": null,
                    "createdAt": "2023-04-07T07:41:07.000Z",
                    "updatedAt": "2023-04-07T07:41:07.000Z",
                    "section_id": 5,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 5,
                        "name": "Секция 4",
                        "theme": "Экосистема решений и практика применения",
                        "order": 5,
                        "createdAt": "2023-03-15T17:21:13.000Z",
                        "updatedAt": "2023-03-15T17:21:13.000Z"
                    },
                    "Speaker": []
                }, {
                    "id": 37,
                    "name": "<p>Перерыв\t</p>",
                    "time": "14:45 – 15:30",
                    "content": "",
                    "order": 6,
                    "presentation": null,
                    "createdAt": "2023-04-07T07:29:43.000Z",
                    "updatedAt": "2023-04-07T07:29:43.000Z",
                    "section_id": 4,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 4,
                        "name": "Секция 3",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 4,
                        "createdAt": "2023-03-14T20:46:22.000Z",
                        "updatedAt": "2023-04-10T09:54:42.000Z"
                    },
                    "Speaker": []
                }, {
                    "id": 4,
                    "name": "<p>Технологии защиты ОС Astra Linux: уникальность и доверие</p>",
                    "time": "11:20 – 11:35",
                    "content": "",
                    "order": 6,
                    "presentation": "https://disk.yandex.ru/i/xFZ9TfrmCLi6VQ",
                    "createdAt": "2023-03-15T17:25:10.000Z",
                    "updatedAt": "2023-05-23T16:55:59.000Z",
                    "section_id": 1,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 1,
                        "name": "Пленарная секция",
                        "theme": "Трансформация рынка и бизнеса",
                        "order": 1,
                        "createdAt": "2023-03-14T20:43:45.000Z",
                        "updatedAt": "2023-04-10T09:54:45.000Z"
                    },
                    "Speaker": [{
                        "id": 21,
                        "name": "Девянин Петр",
                        "job": "Научный руководитель ГК «Астра»",
                        "avatar": "/images/Девянин Петр_Астра.jpg",
                        "info": "",
                        "createdAt": "2023-04-06T07:36:54.000Z",
                        "updatedAt": "2023-04-11T15:17:37.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-06T08:06:13.000Z",
                            "updatedAt": "2023-04-06T08:06:13.000Z",
                            "program_id": 4,
                            "speaker_id": 21
                        }
                    }]
                }, {
                    "id": 30,
                    "name": "<p>Перерыв</p>",
                    "time": "14:45 – 15:30",
                    "content": "",
                    "order": 6,
                    "presentation": null,
                    "createdAt": "2023-04-07T07:05:38.000Z",
                    "updatedAt": "2023-04-23T09:19:33.000Z",
                    "section_id": 3,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 3,
                        "name": "Секция 2",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 3,
                        "createdAt": "2023-03-14T20:45:11.000Z",
                        "updatedAt": "2023-04-10T09:54:43.000Z"
                    },
                    "Speaker": []
                }, {
                    "id": 21,
                    "name": "<p>Перерыв</p>",
                    "time": "14:45 – 15:30",
                    "content": "",
                    "order": 6,
                    "presentation": null,
                    "createdAt": "2023-04-06T08:15:47.000Z",
                    "updatedAt": "2023-04-06T08:18:27.000Z",
                    "section_id": 2,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 2,
                        "name": "Секция 1",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 2,
                        "createdAt": "2023-03-14T20:44:31.000Z",
                        "updatedAt": "2023-04-24T08:39:17.000Z"
                    },
                    "Speaker": []
                }, {
                    "id": 16,
                    "name": "<p>Отечественное ИТ-оборудование для стабильной и надежной работы</p>",
                    "time": "11:35 – 11:50",
                    "content": "",
                    "order": 7,
                    "presentation": "https://disk.yandex.ru/i/9IA8fLXYFG9n4Q",
                    "createdAt": "2023-04-06T08:05:09.000Z",
                    "updatedAt": "2023-05-23T16:56:15.000Z",
                    "section_id": 1,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 1,
                        "name": "Пленарная секция",
                        "theme": "Трансформация рынка и бизнеса",
                        "order": 1,
                        "createdAt": "2023-03-14T20:43:45.000Z",
                        "updatedAt": "2023-04-10T09:54:45.000Z"
                    },
                    "Speaker": [{
                        "id": 70,
                        "name": "Мухортов Александр",
                        "job": "Руководитель отдела Enterprise-решений Treolan",
                        "avatar": "/images/Мухортов.jpg",
                        "info": "",
                        "createdAt": "2023-04-14T17:10:47.000Z",
                        "updatedAt": "2023-04-14T17:14:44.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-14T17:12:20.000Z",
                            "updatedAt": "2023-04-14T17:12:20.000Z",
                            "program_id": 16,
                            "speaker_id": 70
                        }
                    }]
                }, {
                    "id": 38,
                    "name": "<p>Результаты сотрудничества «МойОфис» и ГК «Астра»</p>",
                    "time": "15:30 – 16:00",
                    "content": "",
                    "order": 7,
                    "presentation": "https://disk.yandex.ru/i/CxBAk6wk70KX3g",
                    "createdAt": "2023-04-07T07:30:48.000Z",
                    "updatedAt": "2023-05-23T17:08:57.000Z",
                    "section_id": 4,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 4,
                        "name": "Секция 3",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 4,
                        "createdAt": "2023-03-14T20:46:22.000Z",
                        "updatedAt": "2023-04-10T09:54:42.000Z"
                    },
                    "Speaker": [{
                        "id": 78,
                        "name": "Фенюшин Евгений",
                        "job": "Директор по продуктам МойОфис",
                        "avatar": "/images/МойОфис_Фенюшин Евгений.jpg",
                        "info": "",
                        "createdAt": "2023-04-21T12:27:33.000Z",
                        "updatedAt": "2023-04-21T12:29:09.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-21T12:28:38.000Z",
                            "updatedAt": "2023-04-21T12:28:38.000Z",
                            "program_id": 38,
                            "speaker_id": 78
                        }
                    }]
                }, {
                    "id": 46,
                    "name": "<p>WorksPad – лучшая отечественная EMM-система</p>",
                    "time": "15:30 – 16:00",
                    "content": "",
                    "order": 7,
                    "presentation": "https://disk.yandex.ru/i/-NS8rUSwIgjIlg",
                    "createdAt": "2023-04-07T07:41:49.000Z",
                    "updatedAt": "2023-05-23T17:11:44.000Z",
                    "section_id": 5,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 5,
                        "name": "Секция 4",
                        "theme": "Экосистема решений и практика применения",
                        "order": 5,
                        "createdAt": "2023-03-15T17:21:13.000Z",
                        "updatedAt": "2023-03-15T17:21:13.000Z"
                    },
                    "Speaker": [{
                        "id": 53,
                        "name": "Макарьин Сергей ",
                        "job": "Директор департамента WorksPad ГК «Астра»",
                        "avatar": "/images/Макарьин.jpg",
                        "info": "",
                        "createdAt": "2023-04-11T07:42:05.000Z",
                        "updatedAt": "2023-04-24T09:39:47.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-11T07:43:03.000Z",
                            "updatedAt": "2023-04-11T07:43:03.000Z",
                            "program_id": 46,
                            "speaker_id": 53
                        }
                    }]
                }, {
                    "id": 29,
                    "name": "<p>Termidesk: комплексный подход к решению бизнес-задач</p>",
                    "time": "15:30 – 16:00",
                    "content": "",
                    "order": 7,
                    "presentation": "https://disk.yandex.ru/i/RX-Y1DfHFbN-HQ",
                    "createdAt": "2023-04-07T07:04:41.000Z",
                    "updatedAt": "2023-05-23T17:05:04.000Z",
                    "section_id": 3,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 3,
                        "name": "Секция 2",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 3,
                        "createdAt": "2023-03-14T20:45:11.000Z",
                        "updatedAt": "2023-04-10T09:54:43.000Z"
                    },
                    "Speaker": [{
                        "id": 32,
                        "name": "Мухин Денис",
                        "job": "Директор по развитию бизнеса «Увеон - облачные технологии», ГК «Астра»",
                        "avatar": "/images/Мухин Денис.jpg",
                        "info": "",
                        "createdAt": "2023-04-07T07:04:11.000Z",
                        "updatedAt": "2023-04-24T08:51:04.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-07T07:04:41.000Z",
                            "updatedAt": "2023-04-07T07:04:41.000Z",
                            "program_id": 29,
                            "speaker_id": 32
                        }
                    }]
                }, {
                    "id": 25,
                    "name": "<p>Платформа Tantor: современный подход управления корпоративными базами данных</p>",
                    "time": "15:30 – 16:00",
                    "content": "",
                    "order": 7,
                    "presentation": "https://disk.yandex.ru/i/KYeY_84r5v2P-g",
                    "createdAt": "2023-04-07T06:56:19.000Z",
                    "updatedAt": "2023-05-23T17:00:01.000Z",
                    "section_id": 2,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 2,
                        "name": "Секция 1",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 2,
                        "createdAt": "2023-03-14T20:44:31.000Z",
                        "updatedAt": "2023-04-24T08:39:17.000Z"
                    },
                    "Speaker": [{
                        "id": 73,
                        "name": "Лычангин Александр",
                        "job": "Руководитель разработки Платформы Tantor ГК «Астра»",
                        "avatar": "/images/Лычангин Александр.jpg",
                        "info": "",
                        "createdAt": "2023-04-17T16:39:01.000Z",
                        "updatedAt": "2023-04-24T08:59:52.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-17T16:39:22.000Z",
                            "updatedAt": "2023-04-17T16:39:22.000Z",
                            "program_id": 25,
                            "speaker_id": 73
                        }
                    }]
                }, {
                    "id": 6,
                    "name": "<p>Эра гибридной работы с TrueConf: ВКС + мессенджер на сотни тысяч сотрудников</p>",
                    "time": "16:00 – 16:30",
                    "content": "",
                    "order": 8,
                    "presentation": "https://disk.yandex.ru/i/W65ku05SXsIoGA",
                    "createdAt": "2023-03-15T17:33:10.000Z",
                    "updatedAt": "2023-05-23T17:09:10.000Z",
                    "section_id": 4,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 4,
                        "name": "Секция 3",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 4,
                        "createdAt": "2023-03-14T20:46:22.000Z",
                        "updatedAt": "2023-04-10T09:54:42.000Z"
                    },
                    "Speaker": [{
                        "id": 12,
                        "name": "Дмитрий Одинцов",
                        "job": "Директор по развитию TrueConf",
                        "avatar": "/images/Дмитрий Одинцов_TrueConf.jpg",
                        "info": "",
                        "createdAt": "2023-03-23T13:29:13.000Z",
                        "updatedAt": "2023-03-24T10:08:01.000Z",
                        "program_speakers": {
                            "createdAt": "2023-03-24T09:54:13.000Z",
                            "updatedAt": "2023-03-24T09:54:13.000Z",
                            "program_id": 6,
                            "speaker_id": 12
                        }
                    }]
                }, {
                    "id": 57,
                    "name": "<p>Как перейти на новое офисное ПО без боли и потерь</p>",
                    "time": "16:00 – 16:30",
                    "content": "",
                    "order": 8,
                    "presentation": "https://disk.yandex.ru/i/ttbIdIMziYiL_Q",
                    "createdAt": "2023-04-13T12:58:25.000Z",
                    "updatedAt": "2023-05-23T17:12:34.000Z",
                    "section_id": 5,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 5,
                        "name": "Секция 4",
                        "theme": "Экосистема решений и практика применения",
                        "order": 5,
                        "createdAt": "2023-03-15T17:21:13.000Z",
                        "updatedAt": "2023-03-15T17:21:13.000Z"
                    },
                    "Speaker": [{
                        "id": 68,
                        "name": "Кокоев Алексей ",
                        "job": "Руководитель центра по работе с государственными организациями «Р7-Офис»",
                        "avatar": "/images/Кокоев Алексей_R7.jpg",
                        "info": "",
                        "createdAt": "2023-04-13T12:57:24.000Z",
                        "updatedAt": "2023-04-24T09:40:18.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-13T12:58:25.000Z",
                            "updatedAt": "2023-04-13T12:58:25.000Z",
                            "program_id": 57,
                            "speaker_id": 68
                        }
                    }]
                }, {
                    "id": 22,
                    "name": "<p><span style=\"color: rgb(77, 77, 77);\">Axoft: от импортозамещения к цифровому суверенитету</span></p>",
                    "time": "11:50 – 12:05",
                    "content": "",
                    "order": 8,
                    "presentation": "https://disk.yandex.ru/i/hkBvBcF_sR_dbw",
                    "createdAt": "2023-04-07T06:49:08.000Z",
                    "updatedAt": "2023-05-23T16:56:42.000Z",
                    "section_id": 1,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 1,
                        "name": "Пленарная секция",
                        "theme": "Трансформация рынка и бизнеса",
                        "order": 1,
                        "createdAt": "2023-03-14T20:43:45.000Z",
                        "updatedAt": "2023-04-10T09:54:45.000Z"
                    },
                    "Speaker": [{
                        "id": 58,
                        "name": "Табулин Сергей",
                        "job": "Директор по продуктам и технологиям Axoft Global",
                        "avatar": "/images/Табулин Сергей.png",
                        "info": "",
                        "createdAt": "2023-04-12T16:18:21.000Z",
                        "updatedAt": "2023-04-24T08:55:12.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-12T16:18:56.000Z",
                            "updatedAt": "2023-04-12T16:18:56.000Z",
                            "program_id": 22,
                            "speaker_id": 58
                        }
                    }]
                }, {
                    "id": 31,
                    "name": "<p>Astra Mobile на планшетах MIG: возможности мобильного режима</p>",
                    "time": "16:00 – 16:30",
                    "content": "",
                    "order": 8,
                    "presentation": "https://disk.yandex.ru/i/Xh7vaqqNvvNd0w",
                    "createdAt": "2023-04-07T07:06:33.000Z",
                    "updatedAt": "2023-05-23T17:05:17.000Z",
                    "section_id": 3,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 3,
                        "name": "Секция 2",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 3,
                        "createdAt": "2023-03-14T20:45:11.000Z",
                        "updatedAt": "2023-04-10T09:54:43.000Z"
                    },
                    "Speaker": [{
                        "id": 61,
                        "name": "Манцветова Анна ",
                        "job": "Коммерческий директор Mobile Inform Group",
                        "avatar": "/images/Манцветова Анна.jpg",
                        "info": "",
                        "createdAt": "2023-04-13T12:28:34.000Z",
                        "updatedAt": "2023-04-13T12:28:34.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-13T12:28:49.000Z",
                            "updatedAt": "2023-04-13T12:28:49.000Z",
                            "program_id": 31,
                            "speaker_id": 61
                        }
                    }, {
                        "id": 33,
                        "name": "Махмадиев Шухрат",
                        "job": "Технический руководитель проектов ГК «Астра»",
                        "avatar": "/images/махмадиев.jpeg",
                        "info": "",
                        "createdAt": "2023-04-07T07:05:58.000Z",
                        "updatedAt": "2023-04-11T15:18:40.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-07T07:06:33.000Z",
                            "updatedAt": "2023-04-07T07:06:33.000Z",
                            "program_id": 31,
                            "speaker_id": 33
                        }
                    }]
                }, {
                    "id": 26,
                    "name": "<p>Как надо и как не надо защищать защищенную ОС наложенными средствами защиты</p>",
                    "time": "16:00 – 16:30",
                    "content": "",
                    "order": 8,
                    "presentation": "https://disk.yandex.ru/i/RpuqPnbQCuEWaA",
                    "createdAt": "2023-04-07T06:57:31.000Z",
                    "updatedAt": "2023-05-23T17:01:29.000Z",
                    "section_id": 2,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 2,
                        "name": "Секция 1",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 2,
                        "createdAt": "2023-03-14T20:44:31.000Z",
                        "updatedAt": "2023-04-24T08:39:17.000Z"
                    },
                    "Speaker": [{
                        "id": 26,
                        "name": "Коростелев Павел",
                        "job": "Руководитель отдела продвижения продуктов компании «Код Безопасности»",
                        "avatar": "/images/Коростелев Павел3_код безопасности.jpg",
                        "info": "",
                        "createdAt": "2023-04-06T07:54:00.000Z",
                        "updatedAt": "2023-04-24T09:01:14.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-07T06:57:31.000Z",
                            "updatedAt": "2023-04-07T06:57:31.000Z",
                            "program_id": 26,
                            "speaker_id": 26
                        }
                    }]
                }, {
                    "id": 39,
                    "name": "<p>MasterSCADA 4D – комплексное решение задач автоматизации и диспетчеризации в промышленности</p>",
                    "time": "16:30 – 17:00",
                    "content": "",
                    "order": 9,
                    "presentation": "https://disk.yandex.ru/i/lYF9UTWA-nxn_Q",
                    "createdAt": "2023-04-07T07:31:51.000Z",
                    "updatedAt": "2023-05-23T17:09:25.000Z",
                    "section_id": 4,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 4,
                        "name": "Секция 3",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 4,
                        "createdAt": "2023-03-14T20:46:22.000Z",
                        "updatedAt": "2023-04-10T09:54:42.000Z"
                    },
                    "Speaker": [{
                        "id": 79,
                        "name": "Станислав Кордупель",
                        "job": "Менеджер по развитию \"МПС софт\"",
                        "avatar": "/images/no_foto-600x600.jpg",
                        "info": "",
                        "createdAt": "2023-04-25T11:26:26.000Z",
                        "updatedAt": "2023-04-25T11:26:26.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-25T11:26:59.000Z",
                            "updatedAt": "2023-04-25T11:26:59.000Z",
                            "program_id": 39,
                            "speaker_id": 79
                        }
                    }]
                }, {
                    "id": 47,
                    "name": "<p>RuPost – легкое импортозамещение почтовой системы</p>",
                    "time": "16:30 – 17:00",
                    "content": "",
                    "order": 9,
                    "presentation": "https://disk.yandex.ru/i/fttdkOKkmkX3Jg",
                    "createdAt": "2023-04-07T07:42:22.000Z",
                    "updatedAt": "2023-05-23T17:12:53.000Z",
                    "section_id": 5,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 5,
                        "name": "Секция 4",
                        "theme": "Экосистема решений и практика применения",
                        "order": 5,
                        "createdAt": "2023-03-15T17:21:13.000Z",
                        "updatedAt": "2023-03-15T17:21:13.000Z"
                    },
                    "Speaker": [{
                        "id": 54,
                        "name": "Зарембо Михаил ",
                        "job": "Технический руководитель проектов RuPost ГК «Астра»",
                        "avatar": "/images/Заремба Михаил.jpg",
                        "info": "",
                        "createdAt": "2023-04-11T07:42:22.000Z",
                        "updatedAt": "2023-04-24T09:40:57.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-11T07:43:12.000Z",
                            "updatedAt": "2023-04-11T07:43:12.000Z",
                            "program_id": 47,
                            "speaker_id": 54
                        }
                    }]
                }, {
                    "id": 48,
                    "name": "<p>Аквариус: проблематика цифровой трансформации в условиях импортозамещения</p>",
                    "time": "16:30 – 17:00",
                    "content": "",
                    "order": 9,
                    "presentation": "https://disk.yandex.ru/i/OWUsWH7Xy7QAOg",
                    "createdAt": "2023-04-07T17:54:47.000Z",
                    "updatedAt": "2023-05-23T17:05:40.000Z",
                    "section_id": 3,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 3,
                        "name": "Секция 2",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 3,
                        "createdAt": "2023-03-14T20:45:11.000Z",
                        "updatedAt": "2023-04-10T09:54:43.000Z"
                    },
                    "Speaker": [{
                        "id": 56,
                        "name": "Дмитриев Александр",
                        "job": "Эксперт по цифровой трансформации компании «Аквариус» ",
                        "avatar": "/images/Дмитриев Александр.jpg",
                        "info": "",
                        "createdAt": "2023-04-11T18:06:53.000Z",
                        "updatedAt": "2023-04-13T12:30:56.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-11T18:07:30.000Z",
                            "updatedAt": "2023-04-11T18:07:30.000Z",
                            "program_id": 48,
                            "speaker_id": 56
                        }
                    }]
                }, {
                    "id": 11,
                    "name": "<p>Инфраструктурный менеджер паролей</p>",
                    "time": "16:30 – 17:00",
                    "content": "",
                    "order": 9,
                    "presentation": "https://disk.yandex.ru/i/Kyh4Rvgnpt0CDg",
                    "createdAt": "2023-03-20T09:47:07.000Z",
                    "updatedAt": "2023-05-23T17:02:19.000Z",
                    "section_id": 2,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 2,
                        "name": "Секция 1",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 2,
                        "createdAt": "2023-03-14T20:44:31.000Z",
                        "updatedAt": "2023-04-24T08:39:17.000Z"
                    },
                    "Speaker": [{
                        "id": 29,
                        "name": "Бубело Оксана",
                        "job": "Руководитель проектов направления «Информационная безопасность» Лиги Цифровой Экономики",
                        "avatar": "/images/Бубело Оксана_AtConsulting.jpg",
                        "info": "",
                        "createdAt": "2023-04-07T06:58:34.000Z",
                        "updatedAt": "2023-04-07T07:47:26.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-07T06:59:24.000Z",
                            "updatedAt": "2023-04-07T06:59:24.000Z",
                            "program_id": 11,
                            "speaker_id": 29
                        }
                    }]
                }, {
                    "id": 23,
                    "name": "<p>Перерыв</p>",
                    "time": "12:05 – 12:40",
                    "content": "",
                    "order": 9,
                    "presentation": null,
                    "createdAt": "2023-04-07T06:49:39.000Z",
                    "updatedAt": "2023-04-07T06:49:39.000Z",
                    "section_id": 1,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 1,
                        "name": "Пленарная секция",
                        "theme": "Трансформация рынка и бизнеса",
                        "order": 1,
                        "createdAt": "2023-03-14T20:43:45.000Z",
                        "updatedAt": "2023-04-10T09:54:45.000Z"
                    },
                    "Speaker": []
                }, {
                    "id": 51,
                    "name": "<p>Практика применения решений на основе ИИ в системах комплексной безопасности общественных зданий</p>",
                    "time": "17:00 – 17:30",
                    "content": "",
                    "order": 10,
                    "presentation": "https://disk.yandex.ru/i/wFeyN9pCCGfqnw",
                    "createdAt": "2023-04-13T12:34:25.000Z",
                    "updatedAt": "2023-05-23T17:02:57.000Z",
                    "section_id": 2,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 2,
                        "name": "Секция 1",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 2,
                        "createdAt": "2023-03-14T20:44:31.000Z",
                        "updatedAt": "2023-04-24T08:39:17.000Z"
                    },
                    "Speaker": [{
                        "id": 75,
                        "name": "Стрельников Алексей",
                        "job": "Директор департамента развития бизнеса и поддержки продаж компании «Хайтэк-Интеграция»",
                        "avatar": "/images/Стрельников Алексей_Хайтек.jpeg",
                        "info": "",
                        "createdAt": "2023-04-17T16:48:57.000Z",
                        "updatedAt": "2023-04-17T16:48:57.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-17T16:49:15.000Z",
                            "updatedAt": "2023-04-17T16:49:15.000Z",
                            "program_id": 51,
                            "speaker_id": 75
                        }
                    }]
                }, {
                    "id": 33,
                    "name": "<p>Аналитика без кода? Biplane24 для ОС Astra Linux</p>",
                    "time": "17:00 – 17:30",
                    "content": "",
                    "order": 10,
                    "presentation": "https://disk.yandex.ru/i/nmhQ8WA-T1ZvFQ",
                    "createdAt": "2023-04-07T07:08:35.000Z",
                    "updatedAt": "2023-05-23T17:05:55.000Z",
                    "section_id": 3,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 3,
                        "name": "Секция 2",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 3,
                        "createdAt": "2023-03-14T20:45:11.000Z",
                        "updatedAt": "2023-04-10T09:54:43.000Z"
                    },
                    "Speaker": [{
                        "id": 62,
                        "name": "Попов Николай",
                        "job": "Основатель компании «Биплан»",
                        "avatar": "/images/Попов Николай2.jpg",
                        "info": "",
                        "createdAt": "2023-04-13T12:32:57.000Z",
                        "updatedAt": "2023-04-17T08:29:15.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-13T12:33:21.000Z",
                            "updatedAt": "2023-04-13T12:33:21.000Z",
                            "program_id": 33,
                            "speaker_id": 62
                        }
                    }]
                }, {
                    "id": 53,
                    "name": "<p>Миграция АБС на импортозамещенные решения. Факторы успеха, «подводные камни», эффективная организация проекта</p>",
                    "time": "17:00 – 17:30",
                    "content": "",
                    "order": 10,
                    "presentation": "https://disk.yandex.ru/i/VFLXWAR9Yz6lHQ",
                    "createdAt": "2023-04-13T12:44:19.000Z",
                    "updatedAt": "2023-05-23T17:09:55.000Z",
                    "section_id": 4,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 4,
                        "name": "Секция 3",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 4,
                        "createdAt": "2023-03-14T20:46:22.000Z",
                        "updatedAt": "2023-04-10T09:54:42.000Z"
                    },
                    "Speaker": [{
                        "id": 72,
                        "name": "Каримов Ринат  ",
                        "job": "Управляющий директор по банковским продуктам R-Style Softlab",
                        "avatar": "/images/Каримов Ринат2.jpg",
                        "info": "",
                        "createdAt": "2023-04-17T08:32:38.000Z",
                        "updatedAt": "2023-04-17T08:34:42.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-17T08:32:57.000Z",
                            "updatedAt": "2023-04-17T08:32:57.000Z",
                            "program_id": 53,
                            "speaker_id": 72
                        }
                    }]
                }, {
                    "id": 58,
                    "name": "<p>Российские средства сетевой безопасности: С-Терра VPN и ОС Astra Linux</p>",
                    "time": "17:00 – 17:30",
                    "content": "",
                    "order": 10,
                    "presentation": "https://disk.yandex.ru/i/NAA4FnCqaA8kFQ",
                    "createdAt": "2023-04-13T13:00:28.000Z",
                    "updatedAt": "2023-05-23T17:13:33.000Z",
                    "section_id": 5,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 5,
                        "name": "Секция 4",
                        "theme": "Экосистема решений и практика применения",
                        "order": 5,
                        "createdAt": "2023-03-15T17:21:13.000Z",
                        "updatedAt": "2023-03-15T17:21:13.000Z"
                    },
                    "Speaker": [{
                        "id": 76,
                        "name": "Мартышев Андрей",
                        "job": "Инженер отдела технического консалтинга  «С-Терра СиЭсПи»",
                        "avatar": "/images/Мартышев Андрей_С-Терра.jpg",
                        "info": "",
                        "createdAt": "2023-04-17T16:51:47.000Z",
                        "updatedAt": "2023-04-24T09:42:31.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-17T16:52:14.000Z",
                            "updatedAt": "2023-04-17T16:52:14.000Z",
                            "program_id": 58,
                            "speaker_id": 76
                        }
                    }]
                }, {
                    "id": 54,
                    "name": "<p>Организация безопасных рабочих мест в облаке VK</p>",
                    "time": "17:30 – 18:00",
                    "content": "",
                    "order": 11,
                    "presentation": "https://disk.yandex.ru/i/IR8X4n4edfKCbA",
                    "createdAt": "2023-04-13T12:44:56.000Z",
                    "updatedAt": "2023-05-23T17:07:30.000Z",
                    "section_id": 4,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 4,
                        "name": "Секция 3",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 4,
                        "createdAt": "2023-03-14T20:46:22.000Z",
                        "updatedAt": "2023-04-10T09:54:42.000Z"
                    },
                    "Speaker": [{
                        "id": 71,
                        "name": "Ирина Капранова",
                        "job": "Менеджер по развитию бизнеса VK Cloud",
                        "avatar": "/images/VK_Ирина Капранова.jpg",
                        "info": "",
                        "createdAt": "2023-04-14T17:17:27.000Z",
                        "updatedAt": "2023-04-24T09:50:34.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-14T17:17:49.000Z",
                            "updatedAt": "2023-04-14T17:17:49.000Z",
                            "program_id": 54,
                            "speaker_id": 71
                        }
                    }]
                }, {
                    "id": 60,
                    "name": "<p>AWADA – российская BMS-платформа для управления инженерной инфраструктурой здания</p>",
                    "time": "17:30 – 18:00",
                    "content": "",
                    "order": 11,
                    "presentation": "https://disk.yandex.ru/i/NqUY4SdSx657CA",
                    "createdAt": "2023-04-20T12:07:30.000Z",
                    "updatedAt": "2023-05-23T17:03:18.000Z",
                    "section_id": 2,
                    "day_id": 1,
                    "programDay": {
                        "id": 1,
                        "name": "День 1",
                        "createdAt": "2023-03-14T20:42:53.000Z",
                        "updatedAt": "2023-03-14T20:42:53.000Z"
                    },
                    "programSection": {
                        "id": 2,
                        "name": "Секция 1",
                        "theme": "Экосистема решений и практика применения ",
                        "order": 2,
                        "createdAt": "2023-03-14T20:44:31.000Z",
                        "updatedAt": "2023-04-24T08:39:17.000Z"
                    },
                    "Speaker": [{
                        "id": 77,
                        "name": "Куликов Михаил",
                        "job": "Исполнительный директор ООО «Авада системс»",
                        "avatar": "/images/Awada_Kulikov Mikhail.JPG",
                        "info": "",
                        "createdAt": "2023-04-20T12:06:38.000Z",
                        "updatedAt": "2023-04-20T12:06:38.000Z",
                        "program_speakers": {
                            "createdAt": "2023-04-20T12:07:30.000Z",
                            "updatedAt": "2023-04-20T12:07:30.000Z",
                            "program_id": 60,
                            "speaker_id": 77
                        }
                    }]
                }])
        }
    },
    modules: {}
})
