<template>
  <div id="app">
    <div class="page">
      <div class="page__content">
        <top-all v-if="getRouteName !== 'Home'"></top-all>
        <!--        <menu-fixed></menu-fixed>-->
        <router-view/>
      </div>
      <div class="page__footer">
        <main-footer></main-footer>
        <notifications position="bottom right"/>
        <good-register></good-register>
        <fail-login></fail-login>
        <user-modal></user-modal>
        <callback-form></callback-form>
      </div>
    </div>
  </div>
</template>
<script>
import MainFooter from "./components/Layout/MainFooter";
import GoodRegister from "./components/Modals/GoodRegister";
import FailLogin from "./components/Modals/FailLogin";
import UserModal from "./components/Modals/UserModal";
import CallbackForm from "./components/Modals/CallbackForm";
// import MenuFixed from "./components/Layout/MenuFixed";
import TopAll from "./components/Layout/TopAll";

export default {
  name: 'App',
  components: {
    TopAll,
    // MenuFixed,
    CallbackForm, UserModal, FailLogin, GoodRegister, MainFooter
  },
  mounted() {
    this.$store.dispatch('loadEvent')
    this.$store.dispatch('loadSettings')
    this.$store.dispatch('loadProgram')
    const noscript = document.createElement('noscript');
    const img = document.createElement('img');
    img.src = 'https://vk.com/rtrg?p=VK-RTRG-1725932-aFH6c';
    img.style.position = 'fixed';
    img.style.left = '-999px';
    img.alt = '';
    noscript.appendChild(img);

    const script = document.createElement('script');
// Установить значение атрибута "type"
    script.type = 'text/javascript';
// Установить значение атрибута "src" (если скрипт находится во внешнем файле)
// Установить текст скрипта (если скрипт находится внутри тега <script>)
    script.text = '!function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src=\'https://vk.com/js/api/openapi.js?169\',t.onload=function(){VK.Retargeting.Init("VK-RTRG-1725932-aFH6c"),VK.Retargeting.Hit()},document.head.appendChild(t)}();';
// Найти элемент head на странице
    const head = document.head || document.getElementsByTagName('head')[0];
// Добавить скрипт в элемент head
    head.appendChild(script);
    head.appendChild(noscript);
  },
  computed: {
    getUser() {
      return this.$store.getters.isAuth
    },
    getJivo() {
      return this.$store.getters.getSettingByValue('jivo')
    },
    getRouteName() {
      return this.$route.name
    }
  },
  watch: {
    getUser() {
      if (this.$store.getters.getUser) {
        this.sockets.subscribe('CHANGE_USER', data => {
          if (data.status === 0 && data.id === this.$store.getters.getUser.id) {
            this.$modal.show('fail-login', {text: 'Вам отказано в участии в мероприятии'})
            this.$cookie.delete('token')
            this.$store.commit('changeUser')
            this.$cookie.delete('rememberToken')
          }
        })
      }
    },
    getJivo() {
      if (this.getJivo) {
        let jivo = document.createElement('script')
        jivo.setAttribute('src', ' //code-sb1.jivosite.com/widget/' + this.getJivo)
        jivo.setAttribute('async', true)
        document.head.appendChild(jivo)
      }
    }
  },
  sockets: {
    connect() {
      console.log('connect')
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  min-height: 100vh;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__content {
    flex: 1 0 auto;
  }

  &__footer {
    flex: 0 0 auto;
  }
}
</style>
