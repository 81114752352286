<template>
  <div class="page">
    <div class="page__top" :style="{backgroundImage: `url('${require('@/assets/background.jpg')}`}">
      <home-top></home-top>
      <home-bottom></home-bottom>
    </div>
    <transition
        name="fade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut">
      <register v-show="getModal === 'register'" v-if="!$store.getters.isAuth"></register>
    </transition>
    <transition
        name="slide"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut">
      <login v-show="getModal === 'login'" v-if="!$store.getters.isAuth"></login>
    </transition>
    <transition
        name="slide"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut">
      <forgot-password v-show="getModal === 'forgot'" v-if="!$store.getters.isAuth"></forgot-password>
    </transition>
    <div class="container py-4">
      <div class="row">
        <div class="col-12">
          <div class="ratio-16x9 ratio">
            <iframe class="w-100 h-100 " src="https://rutube.ru/play/embed/f06c8bf0a6bafa0d45c8549e5c5dc663"
                    frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen
                    allowFullScreen></iframe>
          </div>

        </div>
      </div>
    </div>
    <program id="section-program"></program>
    <partners></partners>
    <votes v-if="isAuth"></votes>
    <f-a-q></f-a-q>
  </div>
</template>

<script>
// @ is an alias to /src

import HomeTop from "../components/Home/HomeTop";
import Register from "../components/Auth/Register";
import HomeBottom from "../components/Home/HomeBottom";
import Login from "../components/Auth/Login";
import FAQ from "../components/Home/FAQ";
import Program from "../components/Home/Program";
import ForgotPassword from "../components/Auth/ForgotPassword";
import Partners from "../components/Home/Partners";
import Votes from "../components/Home/Votes";
//
export default {
  name: 'Home',
  components: {
    Votes,
    Partners,
    ForgotPassword,
    Program,
    FAQ,
    Login,
    HomeBottom,
    Register,
    HomeTop
  },
  computed: {
    isAuth() {
      return this.$store.getters.isAuth
    },
    getModal() {
      return this.$store.getters.getRightModal
    }
  },
  methods: {
    clickToCloseModal(target) {
      let classes = target && target.classList ? target.classList.value : ''
      if (classes.indexOf('button') === -1) {
        if (classes.indexOf('page') !== -1) {
          return this.$store.commit('changeRightModal', null)
        }
        if (classes.indexOf('callback') === -1 && target && target.parentNode) {
          return this.clickToCloseModal(target.parentNode)
        }
      }
    }
  },
  watch: {
    getModal() {
      if (this.getModal !== null) {
        document.addEventListener('click', e => this.clickToCloseModal(e.target))
      } else {
        document.removeEventListener('click', this.clickToCloseModal)
      }
    }
  },
  mounted() {
    if (this.$route.hash) {
      this.$scrollTo(this.$route.hash)
    }
    this.$store.dispatch('loadContent')
    if (this.$route.name === 'Verification') {
      if (this.$route.query.type) {
        const query = this.$route.query
        this.$axios.post('user/info-update', query)
            .then(resp => {
              this.$modal.show('fail-login', {text: resp.data.message, title: 'Успешно'})
              // this.$router.push('/')
            })
            .catch(e => {
              this.$modal.show('fail-login', {text: e.response.data.message})
            })
      } else {
        if (this.$route.query.email && this.$route.query.code) {
          this.$modal.show('user-modal')
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  min-height: 100vh;

  &__top {
    overflow-y: hidden;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 100% 90%;
    @include media(992) {
      background-size: auto 90%;
      background-position: top center;
    }
    @include media(768){
      background-size: auto 100%;

    }
    //@media screen and (max-height: 1400px) and (max-width: 992px) {
    //  background-size: auto 75%;
    //
    //}
    //@media screen and (max-height: 1200px) and (max-width: 992px) {
    //  background-size: auto 75%;
    //
    //}
    //@media screen and (max-height: 1100px) and (max-width: 992px) {
    //  background-size: auto 60%;
    //
    //}
    //@media screen and (max-height: 1000px) and (max-width: 992px) {
    //  background-size: auto 75%;
    //
    //}
    //@media screen and (max-height: 900px) and (max-width: 992px) {
    //  background-size: auto 75vh;
    //
    //}
    //@media screen and (max-height: 800px) and (max-width: 992px) {
    //  background-size: auto 90vh;
    //}
    //@media screen and (max-height: 721px) and (max-width: 992px) {
    //  background-size: auto 78vh;
    //}
    //@media screen and (max-height: 700px) and (max-width: 992px) {
    //  background-size: auto 90vh;
    //
    //}

  }
  &__bottom {
  }

}
</style>
