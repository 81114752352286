<template>
  <div class="partners" id="section-partners">
    <partner-section v-for="(val, index, key ) in getPartnersToData" :key="key" :partners="val"
                     :title="index"></partner-section>
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="partners__button">
            <router-link :to="{name: 'Material'}" class="button button_blue">
              Материалы партнеров
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import PartnerSection from "./PartnerSection";

export default {
  name: "Partners",
  components: {PartnerSection},
  // data() {
  //   return {
  //     partners: [
  //       {
  //         "id": 19,
  //         "name": "CNews",
  //         "avatar": "/images/Cnews.png",
  //         "link": "https://www.cnews.ru/",
  //         "group": "Информационные партнеры",
  //         "newPage": true,
  //         "order": 1,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-30T09:13:30.000Z",
  //         "updatedAt": "2023-03-30T09:13:30.000Z"
  //       },
  //       {
  //         "id": 36,
  //         "name": "Треолан",
  //         "avatar": "/images/treolan_logo_rgb.png",
  //         "link": "https://www.treolan.ru/",
  //         "group": "Стратегические партнеры",
  //         "newPage": true,
  //         "order": 1,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-13T17:43:47.000Z",
  //         "updatedAt": "2023-04-17T15:39:04.000Z"
  //       },
  //       {
  //         "id": 17,
  //         "name": "Росэнергоатом",
  //         "avatar": "/images/росэнергоатом.png",
  //         "link": "https://www.rosenergoatom.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 1,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-24T13:56:35.000Z",
  //         "updatedAt": "2023-03-24T13:56:35.000Z"
  //       },
  //       {
  //         "id": 29,
  //         "name": "Axoft",
  //         "avatar": "/images/Axoft.png",
  //         "link": "https://axoftglobal.ru/",
  //         "group": "Стратегические партнеры",
  //         "newPage": true,
  //         "order": 2,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-10T13:02:29.000Z",
  //         "updatedAt": "2023-04-10T13:02:29.000Z"
  //       },
  //       {
  //         "id": 11,
  //         "name": "ICT Online",
  //         "avatar": "/images/Group 109.png",
  //         "link": "https://ict-online.ru/",
  //         "group": "Информационные партнеры",
  //         "newPage": true,
  //         "order": 2,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-20T10:00:41.000Z",
  //         "updatedAt": "2023-03-22T14:30:26.000Z"
  //       },
  //       {
  //         "id": 15,
  //         "name": "Infotecs",
  //         "avatar": "/images/infotecs_logo2.png",
  //         "link": "https://infotecs.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 2,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-23T11:00:38.000Z",
  //         "updatedAt": "2023-03-24T13:53:17.000Z"
  //       },
  //       {
  //         "id": 32,
  //         "name": "Аквариус",
  //         "avatar": "/images/Aquarius.png",
  //         "link": "https://www.aq.ru/",
  //         "group": "Стратегические партнеры",
  //         "newPage": true,
  //         "order": 3,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-11T12:33:36.000Z",
  //         "updatedAt": "2023-04-11T12:33:36.000Z"
  //       },
  //       {
  //         "id": 13,
  //         "name": "IT World",
  //         "avatar": "/images/itworld.jpg",
  //         "link": "https://www.it-world.ru/",
  //         "group": "Информационные партнеры",
  //         "newPage": true,
  //         "order": 3,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-22T13:57:33.000Z",
  //         "updatedAt": "2023-03-22T14:03:06.000Z"
  //       },
  //       {
  //         "id": 42,
  //         "name": "Delta",
  //         "avatar": "/images/delta.png",
  //         "link": "https://deltacomputers.ru/",
  //         "group": "Стратегические партнеры",
  //         "newPage": true,
  //         "order": 4,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-14T17:08:42.000Z",
  //         "updatedAt": "2023-04-17T15:39:12.000Z"
  //       },
  //       {
  //         "id": 16,
  //         "name": "TrueConf",
  //         "avatar": "/images/infotecs_logo.png",
  //         "link": "https://trueconf.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 4,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-24T13:52:29.000Z",
  //         "updatedAt": "2023-03-24T13:52:29.000Z"
  //       },
  //       {
  //         "id": 14,
  //         "name": "OCS Distribution",
  //         "avatar": "/images/rupost (1).png",
  //         "link": "https://www.ocs.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 5,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-23T10:57:12.000Z",
  //         "updatedAt": "2023-03-23T10:57:12.000Z"
  //       },
  //       {
  //         "id": 24,
  //         "name": "Открытые системы",
  //         "avatar": "/images/открытые системы.png",
  //         "link": "https://www.osp.ru/",
  //         "group": "Информационные партнеры",
  //         "newPage": true,
  //         "order": 5,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-06T07:24:03.000Z",
  //         "updatedAt": "2023-04-06T07:24:03.000Z"
  //       },
  //       {
  //         "id": 18,
  //         "name": "Гарант",
  //         "avatar": "/images/garant.png",
  //         "link": "http://linux.garant.ru/?utm_source=astraconf&utm_medium=affiliate&utm_campaign=conf",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 5,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-28T13:28:04.000Z",
  //         "updatedAt": "2023-04-10T13:39:26.000Z"
  //       },
  //       {
  //         "id": 10,
  //         "name": "Global CIO",
  //         "avatar": "/images/Group 108.png",
  //         "link": "https://globalcio.ru/",
  //         "group": "Информационные партнеры",
  //         "newPage": true,
  //         "order": 5,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-20T09:59:27.000Z",
  //         "updatedAt": "2023-03-30T09:12:51.000Z"
  //       },
  //       {
  //         "id": 9,
  //         "name": "ICL Техно",
  //         "avatar": "/images/icl2.png",
  //         "link": "http://icl-techno.ru",
  //         "group": "Стратегические партнеры",
  //         "newPage": true,
  //         "order": 5,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-20T09:57:16.000Z",
  //         "updatedAt": "2023-04-17T15:38:28.000Z"
  //       },
  //       {
  //         "id": 4,
  //         "name": "RuPost",
  //         "avatar": "/images/rupost.png",
  //         "link": "https://www.rupost.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 6,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-20T09:18:44.000Z",
  //         "updatedAt": "2023-03-20T10:56:02.000Z"
  //       },
  //       {
  //         "id": 28,
  //         "name": "Tadviser",
  //         "avatar": "/images/tadviser.png",
  //         "link": "https://www.tadviser.ru/",
  //         "group": "Информационные партнеры",
  //         "newPage": true,
  //         "order": 6,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-10T12:34:55.000Z",
  //         "updatedAt": "2023-04-10T12:35:38.000Z"
  //       },
  //       {
  //         "id": 43,
  //         "name": "Russoft",
  //         "avatar": "/images/russoft2.jpg",
  //         "link": "https://russoft.org/",
  //         "group": "Информационные партнеры",
  //         "newPage": true,
  //         "order": 7,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-14T17:23:21.000Z",
  //         "updatedAt": "2023-04-14T17:23:21.000Z"
  //       },
  //       {
  //         "id": 3,
  //         "name": "BILLmanager",
  //         "avatar": "/images/bill.png",
  //         "link": "https://www.ispsystem.ru",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 7,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-20T09:14:45.000Z",
  //         "updatedAt": "2023-04-21T13:35:53.000Z"
  //       },
  //       {
  //         "id": 44,
  //         "name": "Anti Malware",
  //         "avatar": "/images/Anti Malware2.png",
  //         "link": "https://www.anti-malware.ru",
  //         "group": "Информационные партнеры",
  //         "newPage": true,
  //         "order": 8,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-17T07:43:56.000Z",
  //         "updatedAt": "2023-04-17T07:45:33.000Z"
  //       },
  //       {
  //         "id": 20,
  //         "name": "Mont",
  //         "avatar": "/images/mont.png",
  //         "link": "https://www.mont.ru/ru-ru",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 8,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-31T14:30:50.000Z",
  //         "updatedAt": "2023-03-31T14:30:50.000Z"
  //       },
  //       {
  //         "id": 2,
  //         "name": "ALD Pro",
  //         "avatar": "/images/ald.png",
  //         "link": "http://aldpro.ru",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 9,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-17T08:17:26.000Z",
  //         "updatedAt": "2023-03-20T10:32:51.000Z"
  //       },
  //       {
  //         "id": 45,
  //         "name": "Bis TV",
  //         "avatar": "/images/лого новый на прозрачной.png",
  //         "link": "https://ib-bank.ru/",
  //         "group": "Информационные партнеры",
  //         "newPage": true,
  //         "order": 9,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-17T08:12:28.000Z",
  //         "updatedAt": "2023-04-17T08:12:28.000Z"
  //       },
  //       {
  //         "id": 46,
  //         "name": "Код информационной безопасности",
  //         "avatar": "/images/Код Иб.png",
  //         "link": "https://codeib.ru/",
  //         "group": "Информационные партнеры",
  //         "newPage": true,
  //         "order": 9,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-17T13:07:38.000Z",
  //         "updatedAt": "2023-04-17T13:07:38.000Z"
  //       },
  //       {
  //         "id": 1,
  //         "name": " RuBackup",
  //         "avatar": "/images/rubackup.png",
  //         "link": "https://rubackup.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 10,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-17T08:16:28.000Z",
  //         "updatedAt": "2023-03-17T08:16:28.000Z"
  //       },
  //       {
  //         "id": 53,
  //         "name": "Cyber Media",
  //         "avatar": "/images/кибермедиа.png",
  //         "link": "https://securitymedia.org/",
  //         "group": "Информационные партнеры",
  //         "newPage": true,
  //         "order": 10,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-20T12:14:41.000Z",
  //         "updatedAt": "2023-04-20T12:18:36.000Z"
  //       },
  //       {
  //         "id": 21,
  //         "name": "Tegrus",
  //         "avatar": "/images/tegrus.png",
  //         "link": "https://tegrus.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 12,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-31T15:32:50.000Z",
  //         "updatedAt": "2023-03-31T15:32:50.000Z"
  //       },
  //       {
  //         "id": 6,
  //         "name": "Брест",
  //         "avatar": "/images/brest.png",
  //         "link": "https://astralinux.ru/products/pk-brest/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 12,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-20T09:32:31.000Z",
  //         "updatedAt": "2023-03-20T10:33:17.000Z"
  //       },
  //       {
  //         "id": 7,
  //         "name": "Termidesk",
  //         "avatar": "/images/termidesk.png",
  //         "link": "https://termidesk.ru",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 13,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-20T09:41:01.000Z",
  //         "updatedAt": "2023-03-20T10:33:28.000Z"
  //       },
  //       {
  //         "id": 8,
  //         "name": "Workspad",
  //         "avatar": "/images/workspad.png",
  //         "link": "https://www.workspad.ru",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 14,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-20T09:43:23.000Z",
  //         "updatedAt": "2023-03-20T10:34:35.000Z"
  //       },
  //       {
  //         "id": 12,
  //         "name": "Tantor",
  //         "avatar": "/images/tantor.png",
  //         "link": "https://tantorlabs.ru",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 15,
  //         "hideToIndex": false,
  //         "createdAt": "2023-03-20T10:34:27.000Z",
  //         "updatedAt": "2023-03-20T10:34:27.000Z"
  //       },
  //       {
  //         "id": 23,
  //         "name": "К2Тех",
  //         "avatar": "/images/к2 тех.png",
  //         "link": "https://k2.tech/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 16,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-05T14:24:54.000Z",
  //         "updatedAt": "2023-04-17T15:30:25.000Z"
  //       },
  //       {
  //         "id": 22,
  //         "name": "Гравитон",
  //         "avatar": "/images/гравитон 4.png",
  //         "link": "https://graviton.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 17,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-03T14:04:51.000Z",
  //         "updatedAt": "2023-04-03T14:11:21.000Z"
  //       },
  //       {
  //         "id": 25,
  //         "name": "Код безопасности",
  //         "avatar": "/images/код безопасности.png",
  //         "link": "https://www.securitycode.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 18,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-06T07:30:01.000Z",
  //         "updatedAt": "2023-04-06T07:30:01.000Z"
  //       },
  //       {
  //         "id": 26,
  //         "name": "AT Consulting",
  //         "avatar": "/images/Лига цифровой 4.png",
  //         "link": "https://digitalleague.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 19,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-07T13:52:35.000Z",
  //         "updatedAt": "2023-04-10T17:44:05.000Z"
  //       },
  //       {
  //         "id": 47,
  //         "name": "Арпп",
  //         "avatar": "/images/АрПП.png",
  //         "link": "https://arppsoft.ru/",
  //         "group": "Информационные партнеры",
  //         "newPage": true,
  //         "order": 19,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-17T13:11:24.000Z",
  //         "updatedAt": "2023-04-17T13:11:24.000Z"
  //       },
  //       {
  //         "id": 51,
  //         "name": "IXBT",
  //         "avatar": "/images/ixbt.png",
  //         "link": "https://www.ixbt.com/",
  //         "group": "Информационные партнеры",
  //         "newPage": true,
  //         "order": 20,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-19T10:35:21.000Z",
  //         "updatedAt": "2023-04-19T10:50:48.000Z"
  //       },
  //       {
  //         "id": 58,
  //         "name": "COMNEWS",
  //         "avatar": "/images/comnews.png",
  //         "link": "https://www.comnews.ru/",
  //         "group": "Информационные партнеры",
  //         "newPage": true,
  //         "order": 20,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-24T15:13:28.000Z",
  //         "updatedAt": "2023-04-24T15:20:13.000Z"
  //       },
  //       {
  //         "id": 27,
  //         "name": "MasterSCADA",
  //         "avatar": "/images/мастерскада.png",
  //         "link": "https://masterscada.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 20,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-07T18:10:39.000Z",
  //         "updatedAt": "2023-04-07T18:11:46.000Z"
  //       },
  //       {
  //         "id": 30,
  //         "name": "ContentAI",
  //         "avatar": "/images/Content AI.png",
  //         "link": "https://contentai.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 21,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-10T13:09:03.000Z",
  //         "updatedAt": "2023-04-10T13:09:03.000Z"
  //       },
  //       {
  //         "id": 31,
  //         "name": "р7 ОФИС",
  //         "avatar": "/images/R7.png",
  //         "link": "https://r7-office.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 22,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-10T13:10:34.000Z",
  //         "updatedAt": "2023-04-13T15:14:23.000Z"
  //       },
  //       {
  //         "id": 33,
  //         "name": "Мерлион",
  //         "avatar": "/images/мерлион.png",
  //         "link": "https://merlion.com",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 24,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-11T12:57:49.000Z",
  //         "updatedAt": "2023-04-11T12:59:00.000Z"
  //       },
  //       {
  //         "id": 35,
  //         "name": "М ифногруп",
  //         "avatar": "/images/инфогруп.png",
  //         "link": "https://m-infogroup.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 25,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-13T17:39:53.000Z",
  //         "updatedAt": "2023-04-13T17:39:53.000Z"
  //       },
  //       {
  //         "id": 34,
  //         "name": "Газпром",
  //         "avatar": "/images/1_GPN_logo_rus_blue-on-white.cdr.svg",
  //         "link": "https://www.gazprom-neft.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 26,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-12T15:17:04.000Z",
  //         "updatedAt": "2023-04-12T15:17:04.000Z"
  //       },
  //       {
  //         "id": 37,
  //         "name": "Сбер Интегро",
  //         "avatar": "/images/сберинтегро.png",
  //         "link": "https://sberintegro.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 27,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-13T17:49:22.000Z",
  //         "updatedAt": "2023-04-13T17:54:10.000Z"
  //       },
  //       {
  //         "id": 38,
  //         "name": "Soflab",
  //         "avatar": "/images/1_GPN_logo_rus_blue-on-white.cdr (2).png",
  //         "link": "https://www.softlab.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 28,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-14T16:54:51.000Z",
  //         "updatedAt": "2023-04-14T16:57:32.000Z"
  //       },
  //       {
  //         "id": 39,
  //         "name": "Biplane",
  //         "avatar": "/images/biplane.png",
  //         "link": "https://biplane24.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 29,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-14T17:01:21.000Z",
  //         "updatedAt": "2023-04-14T17:01:21.000Z"
  //       },
  //       {
  //         "id": 40,
  //         "name": "HiTech",
  //         "avatar": "/images/hitech.png",
  //         "link": "https://hi-tech.org/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 30,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-14T17:03:36.000Z",
  //         "updatedAt": "2023-04-14T17:03:36.000Z"
  //       },
  //       {
  //         "id": 41,
  //         "name": "s-terra",
  //         "avatar": "/images/sterra.png",
  //         "link": "https://www.s-terra.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 31,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-14T17:06:33.000Z",
  //         "updatedAt": "2023-04-14T17:06:33.000Z"
  //       },
  //       {
  //         "id": 48,
  //         "name": "Irbis",
  //         "avatar": "/images/Ирбис 2.png",
  //         "link": "https://www.irbis.su/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 33,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-17T14:53:22.000Z",
  //         "updatedAt": "2023-04-17T14:55:04.000Z"
  //       },
  //       {
  //         "id": 49,
  //         "name": "ВК КЛАУД",
  //         "avatar": "/images/Вк клауд.png",
  //         "link": "https://mcs.mail.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 34,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-17T14:58:41.000Z",
  //         "updatedAt": "2023-04-17T14:58:41.000Z"
  //       },
  //       {
  //         "id": 50,
  //         "name": "ИВА",
  //         "avatar": "/images/Ива2.png",
  //         "link": "https://iva-tech.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 35,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-17T14:59:18.000Z",
  //         "updatedAt": "2023-04-21T14:36:23.000Z"
  //       },
  //       {
  //         "id": 52,
  //         "name": "инферит",
  //         "avatar": "/images/инферит.png",
  //         "link": "https://inferit.com/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 36,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-20T08:03:15.000Z",
  //         "updatedAt": "2023-04-21T14:36:35.000Z"
  //       },
  //       {
  //         "id": 54,
  //         "name": "МойОфис",
  //         "avatar": "/images/мойофис.png",
  //         "link": "https://myoffice.ru/",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 37,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-21T13:07:06.000Z",
  //         "updatedAt": "2023-04-24T12:48:00.000Z"
  //       },
  //       {
  //         "id": 55,
  //         "name": "DCImanager",
  //         "avatar": "/images/dci.png",
  //         "link": "https://www.ispsystem.ru/dcimanager",
  //         "group": "Партнеры",
  //         "newPage": false,
  //         "order": 38,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-21T14:32:35.000Z",
  //         "updatedAt": "2023-04-21T14:36:59.000Z"
  //       },
  //       {
  //         "id": 56,
  //         "name": "VMmanager",
  //         "avatar": "/images/vm.png",
  //         "link": "https://www.ispsystem.ru/vmmanager",
  //         "group": "Партнеры",
  //         "newPage": false,
  //         "order": 39,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-21T14:50:22.000Z",
  //         "updatedAt": "2023-04-21T14:50:22.000Z"
  //       },
  //       {
  //         "id": 57,
  //         "name": "Awada",
  //         "avatar": "/images/Авада.png",
  //         "link": "http://awada.ru",
  //         "group": "Партнеры",
  //         "newPage": true,
  //         "order": 40,
  //         "hideToIndex": false,
  //         "createdAt": "2023-04-21T15:05:00.000Z",
  //         "updatedAt": "2023-04-21T15:05:00.000Z"
  //       }
  //     ],
  //   }
  // },
  computed: {
    partners() {
      return this.$store.state.partners
    },
    getPartnersToData() {
      let partners = _.filter(this.partners, partner => !partner.hideToIndex)
      partners = _.groupBy(partners, 'group')
      return {
        'Стратегические партнеры': partners['Стратегические партнеры'],
        'Партнеры': partners['Партнеры'],
        'Информационные партнеры': partners['Информационные партнеры'],
      }
    }
  },
  // mounted() {
  //   this.$axios.get('/partner')
  //       .then(resp => {
  //         this.partners = resp.data
  //       })
  //       .catch(e => {
  //         console.log(e)
  //       })
  // }
}
</script>

<style scoped lang="scss">
.partners {
  padding-top: rem(15);
}
.partners__button {
  max-width: 280px;
  margin: rem(20) auto 0;
}
</style>
