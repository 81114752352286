import Cookie from 'js-cookie'
import axios from "../plugins/axios";
export default ({next}) => {
    if (!Cookie.get('XSRF-TOKEN')) {
        axios.get('csrf').then((resp) => {
            Cookie.set('XSRF-TOKEN', resp.data.csrfToken)
            return next()
        }).catch(e => {
            console.log(e)
            return next()
        })
    }
    return next()
}