<template>
  <modal name="user-modal" :adaptive="true" :height="'auto'" :maxWidth="341" @before-open="beforeOpen">
    <div class="popup__wrapper">
      <div class="popup__title">
        Профиль {{user.email}}
      </div>
      <div class="popup__desc">
        <div class="popup__table">
          <div class="popup__info">ФИО</div>
          <div class="popup__value">{{user.fullName}}</div>
        </div>
        <div class="popup__table">
          <div class="popup__info">Телефон</div>
          <div class="popup__value">{{user.phone}}</div>
        </div>
        <div class="popup__table">
          <div class="popup__info">E-mail</div>
          <div class="popup__value">{{user.email}}</div>
        </div>
        <div class="popup__table">
          <div class="popup__info">Страна</div>
          <div class="popup__value">{{user.country}}</div>
        </div>
        <div class="popup__table">
          <div class="popup__info">Город</div>
          <div class="popup__value">{{user.city}}</div>
        </div>
        <div class="popup__table">
          <div class="popup__info">Организация</div>
          <div class="popup__value">{{user.organization}}</div>
        </div>
        <div class="popup__table">
          <div class="popup__info">Должность</div>
          <div class="popup__value">{{user.post}}</div>
        </div>
        <div class="popup__table">
          <div class="popup__info">Статус регистрации</div>
          <div class="popup__value">{{user.registerStatus}}</div>
        </div>
      </div>
      <div class="popup__button">
        <div class="popup__buttons">
          <button class="button button_green" @click="changeStatus(user.registerStatus)">Принять</button>
          <button class="button button_blue" v-if="user.registerStatus === 'offline'" @click="changeStatus('online')">В Online</button>
          <button class="button button_red" @click="changeStatus('fail')">Отклонить</button>
        </div>
      </div>
    </div>

  </modal>

</template>

<script>
export default {
  name: "UserModal",
  data() {
    return {
      user: {}
    }
  },
  methods: {
    beforeOpen() {
      this.$axios.get(`user/info-update?email=${this.$route.query.email}&code=${this.$route.query.code}`)
        .then(resp => {
          this.user = resp.data
        })
        .catch(e => {
          this.$modal.show('fail-login', {text: e.response.data.message})
          this.$modal.hide('user-modal')
          console.log(e)
        })
    },
    changeStatus(status) {
      let query = this.$route.query
      query.type = status
      this.$modal.hide('user-modal')
      this.$axios.post('user/info-update', query)
          .then(resp => {
            this.$modal.show('fail-login', {text: resp.data.message, title: 'Успешно'})
          })
          .catch(e => {
            this.$modal.show('fail-login', {text: e.response.data.message})
          })
    }
  }
}
</script>

<style scoped lang="scss">
  .popup {
    &__table {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 5px;
    }
    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        border-radius: 0;
        font-size: 14px;
      }
    }
  }
</style>