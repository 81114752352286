<template>
  <section class="section section_partner" v-if="partners">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="title title_dark title_second title_svg  text-uppercase">{{title}}
          </h2>
          <div class="row">
            <div class="col-12 col-lg-3 col-sm-6 col-md-4" v-for="partner in partners" :key="partner.id">
              <div class="partner" >
                <div class="partner__image" v-if="!partner.link">
                  <img :src="partner.avatar" :alt="partner.name">
                </div>
                <div class="partner__image" v-else>
                  <a :href="partner.link" target="_blank" v-if="partner.newPage">
                    <img :src="partner.avatar" :alt="partner.name">
                  </a>
                  <router-link :to="partner.link" v-else>
                    <img :src="partner.avatar" :alt="partner.name">
                  </router-link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PartnerSection",
  props: ['partners', 'title'],
  data() {
    return {
      slickOptions: {
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplaySpeed: 4000,
        infinite: true,
        arrows: false,
        autoPlay: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              rows: 4,
              slidesPerRow: 1,
              slidesToShow: 1
            }
          }
        ]
      },
    }
  },
}
</script>

<style scoped lang="scss">
  .section {
    &_partner {
      padding: rem(20) 0;
    }
  }
  .title {
    padding-bottom: rem(40);
    margin-bottom: 0;
  }
  .partner {
    &::v-deep {
      .partner {
        &__image {
          padding: 0 rem(20);
          @include media(768) {
            padding-bottom: rem(5);
            max-width: 300px;
            margin: 0 auto;
          }
          img {
            max-width: 100%;
            vertical-align: top;
          }

        }
      }
    }
    &__image {
      padding: 0 rem(20);
      img {
        max-width: 100%;
        vertical-align: top;
      }
    }
    &__buttons {
      padding-top: rem(20);
    }
    &__button {
      cursor: pointer;
      svg {
        * {
          transition: all .25s;
        }
      }
      &:hover {
        svg {
          circle {
            fill: #59B980
          }
        }
      }
      &:first-child {
        margin-right: rem(5);
      }
    }
  }
</style>