<template>
  <section class="section section_votes" id="section-votes">
    <div class="votes">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-12 col-md-6 d-none d-md-block">
            <div class="votes__image">
              <img src="@/assets/img/votes11.png" alt="Опросы">
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="votes__desc">
              Опросы от стратегических партнеров
            </div>
            <div class="votes__title">Примите участие в исследовании. Ваш ответ важен!</div>
            <div class="votes__button">
              <router-link :to="{name: 'Votes'}" class="button">Пройти опросы</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Votes"
}
</script>

<style scoped lang="scss">
  .section_votes {
    padding-top: rem(100);
    padding-bottom: rem(70);
    @include media(768) {
      padding-top: rem(20);
      padding-bottom: rem(20);
    }
  }
  .votes {
    position: relative;
    @include media(768) {
      padding: rem(30) 0;
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      background: linear-gradient(114.27deg, #52B5E0 -2.8%, #0079C1 65.54%);
      height: 75%;
      z-index: -1;
      @include media(992) {
        height: 90%;
      }
      @include media(768) {
        top: auto;
        bottom: 0;
        transform: translateY(0);
        height: 85%;
      }

    }
    &__image {
      img {
        max-width: 100%;
        vertical-align: top;
      }
      position: relative;
    }
    &__desc {
      font-style: normal;
      font-weight: 500;
      font-size: rem(18);
      color: #FFFFFF;
      opacity: 0.87;
      @include media(768) {
        text-align: center;
        padding-top: rem(20);
      }
    }
    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: rem(38);
      line-height: 1.47;
      max-width: 600px;
      color: #FFFFFF;
      padding-top: rem(16);
      padding-bottom: rem(30);
      @include media(768) {
        text-align: center;
      }
    }
    &__button {
      max-width: 327px;
      @include media(768) {
        margin: 0 auto;
      }
    }
  }

</style>