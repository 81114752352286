<template>
  <div class="program__text" v-if="program.content.length || program.speaker.length">
    <div class="container">
      <div class="row">
        <div class="col-12" v-if="!getSpeakersCount">
          <div class="program__info" v-html="program.content"></div>
        </div>
        <div class="col-12" v-else>
          <div class="row">
            <div class="col-12">
              <div class="row ">
                <div
                    :class="['col-lg-' + getSpeakerByRow, program.speaker.length && index !== program.speaker.length - 1 ? 'pb-2' : '']"
                    class="col-12" v-for="(speaker, index) in program.speaker" :key="speaker.id">
                  <div class="program__speaker d-flex align-items-center flex-column flex-lg-row"
                       @click="showSpeaker(speaker.id)">
                    <div class="program__avatar">
                      <img :src="speaker.avatar" :alt="speaker.name">
                    </div>
                    <div class="program__cont">
                      <div class="program__name">{{ speaker.name }}</div>
                      <div class="program__job">{{ speaker.job }}</div>
                      <!--                      <div class="program__link pt-2">-->
                      <!--                        <a href="#" @click.prevent="showSpeaker(speaker.id)">Подробнее</a>-->
                      <!--                      </div>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" :class="'col-lg-' + (12 - getSpeakersRows)">
              <div class="program__info" v-html="program.content"></div>
            </div>
          </div>
          <modal :name="'speaker_' + program.id" :adaptive="true" :height="'auto'" :maxWidth="400" :maxHeight="500"
                 :scrollable="true" style="overflow-y: auto">
            <div class="popup__wrapper" v-if="activeSpeaker">
              <div class="row">
                <div class="col-4">
                  <div class="program__avatar program__avatar_small">
                    <img :src="activeSpeaker.avatar" :alt="activeSpeaker.name">
                  </div>
                </div>
                <div class="col-8">
                  <div class="program__title">
                    Тема выступления:
                  </div>
                  <div class="program__theme" v-html="program.name"></div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="speaker__name">{{ activeSpeaker.name }}</div>
                  <div class="speaker__job">{{ activeSpeaker.job }}</div>
                  <div class="speaker__line"></div>
                  <div class="speaker__text" v-if="activeSpeaker.info" v-html="activeSpeaker.info"></div>
                  <div class="row">
                    <div class="col-6">
                      <div
                          class="speaker__replace speaker__replace_prev d-flex align-items-center justify-content-between"
                          @click="showSpeaker(prevSpeaker.id)" v-if="prevSpeaker">
                        <!--                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                        <!--                          <g filter="url(#filter0_d_485_1916)">-->
                        <!--                            <circle cx="22" cy="21" r="21" transform="rotate(-90 22 21)" fill="#59B980"/>-->
                        <!--                          </g>-->
                        <!--                          <path d="M18.2601 31.4879L18.485 31.5383L18.6694 31.4001L28.209 24.2482L28.2097 24.2476C29.6694 23.1489 30.5 21.8687 30.5 20.5C30.5 19.1313 29.6694 17.8511 28.2097 16.7524L28.209 16.7518L18.6694 9.59994L18.485 9.46169L18.2601 9.51211L17.8906 9.59495L16.8322 9.83225L17.7001 10.4829L27.2393 17.6345C28.5336 18.6066 29.0801 19.5975 29.0801 20.5C29.0801 21.4025 28.5336 22.3933 27.2394 23.3654C27.2394 23.3654 27.2393 23.3654 27.2393 23.3655L17.7001 30.5171L16.8322 31.1677L17.8906 31.405L18.2601 31.4879Z" fill="white" stroke="white"/>-->
                        <!--                          <defs>-->
                        <!--                            <filter id="filter0_d_485_1916" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">-->
                        <!--                              <feFlood flood-opacity="0" result="BackgroundImageFix"/>-->
                        <!--                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>-->
                        <!--                              <feOffset dy="1"/>-->
                        <!--                              <feGaussianBlur stdDeviation="0.5"/>-->
                        <!--                              <feComposite in2="hardAlpha" operator="out"/>-->
                        <!--                              <feColorMatrix type="matrix" values="0 0 0 0 0.34902 0 0 0 0 0.72549 0 0 0 0 0.501961 0 0 0 0.77 0"/>-->
                        <!--                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_485_1916"/>-->
                        <!--                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_485_1916" result="shape"/>-->
                        <!--                            </filter>-->
                        <!--                          </defs>-->
                        <!--                        </svg>-->
                        <span>Предыдущий</span>
                      </div>
                    </div>
                    <div class="col-6">
                      <div
                          class="speaker__replace speaker__replace_next d-flex align-items-center justify-content-between"
                          @click="showSpeaker(nextSpeaker.id)" v-if="nextSpeaker">
                        <span>Следующий</span>
                        <!--                        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                        <!--                          <g filter="url(#filter0_d_485_1916)">-->
                        <!--                            <circle cx="22" cy="21" r="21" transform="rotate(-90 22 21)" fill="#59B980"/>-->
                        <!--                          </g>-->
                        <!--                          <path d="M18.2601 31.4879L18.485 31.5383L18.6694 31.4001L28.209 24.2482L28.2097 24.2476C29.6694 23.1489 30.5 21.8687 30.5 20.5C30.5 19.1313 29.6694 17.8511 28.2097 16.7524L28.209 16.7518L18.6694 9.59994L18.485 9.46169L18.2601 9.51211L17.8906 9.59495L16.8322 9.83225L17.7001 10.4829L27.2393 17.6345C28.5336 18.6066 29.0801 19.5975 29.0801 20.5C29.0801 21.4025 28.5336 22.3933 27.2394 23.3654C27.2394 23.3654 27.2393 23.3654 27.2393 23.3655L17.7001 30.5171L16.8322 31.1677L17.8906 31.405L18.2601 31.4879Z" fill="white" stroke="white"/>-->
                        <!--                          <defs>-->
                        <!--                            <filter id="filter0_d_485_1916" x="0" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">-->
                        <!--                              <feFlood flood-opacity="0" result="BackgroundImageFix"/>-->
                        <!--                              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>-->
                        <!--                              <feOffset dy="1"/>-->
                        <!--                              <feGaussianBlur stdDeviation="0.5"/>-->
                        <!--                              <feComposite in2="hardAlpha" operator="out"/>-->
                        <!--                              <feColorMatrix type="matrix" values="0 0 0 0 0.34902 0 0 0 0 0.72549 0 0 0 0 0.501961 0 0 0 0.77 0"/>-->
                        <!--                              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_485_1916"/>-->
                        <!--                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_485_1916" result="shape"/>-->
                        <!--                            </filter>-->
                        <!--                          </defs>-->
                        <!--                        </svg>-->

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </modal>
        </div>
        <div class="col-12" v-if="isMaterial">
          <a :href="program.presentation" style="max-width: 240px; margin: 0 auto" target="_blank" class="button button_blue text-center">Скачать
            презентацию
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                 style="margin-left: 5px"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M19.8975 7.8525L14.6475 2.6025C14.5421 2.49698 14.3991 2.43763 14.25 2.4375H5.25C4.9019 2.4375 4.56806 2.57578 4.32192 2.82192C4.07578 3.06806 3.9375 3.4019 3.9375 3.75V20.25C3.9375 20.5981 4.07578 20.9319 4.32192 21.1781C4.56806 21.4242 4.9019 21.5625 5.25 21.5625H18.75C19.0981 21.5625 19.4319 21.4242 19.6781 21.1781C19.9242 20.9319 20.0625 20.5981 20.0625 20.25V8.25C20.0624 8.10087 20.003 7.95789 19.8975 7.8525ZM14.8125 4.3575L18.1425 7.6875H14.8125V4.3575ZM18.75 20.4375H5.25C5.20027 20.4375 5.15258 20.4177 5.11742 20.3826C5.08225 20.3474 5.0625 20.2997 5.0625 20.25V3.75C5.0625 3.70027 5.08225 3.65258 5.11742 3.61742C5.15258 3.58225 5.20027 3.5625 5.25 3.5625H13.6875V8.25C13.6875 8.39918 13.7468 8.54226 13.8523 8.64775C13.9577 8.75324 14.1008 8.8125 14.25 8.8125H18.9375V20.25C18.9375 20.2997 18.9177 20.3474 18.8826 20.3826C18.8474 20.4177 18.7997 20.4375 18.75 20.4375ZM14.6475 14.6025C14.7528 14.708 14.812 14.8509 14.812 15C14.812 15.1491 14.7528 15.292 14.6475 15.3975L12.3975 17.6475C12.292 17.7528 12.1491 17.812 12 17.812C11.8509 17.812 11.708 17.7528 11.6025 17.6475L9.3525 15.3975C9.25314 15.2909 9.19905 15.1498 9.20162 15.0041C9.20419 14.8584 9.26322 14.7193 9.36628 14.6163C9.46934 14.5132 9.60838 14.4542 9.75411 14.4516C9.89983 14.449 10.0409 14.5031 10.1475 14.6025L11.4375 15.8916V11.25C11.4375 11.1008 11.4968 10.9577 11.6023 10.8523C11.7077 10.7468 11.8508 10.6875 12 10.6875C12.1492 10.6875 12.2923 10.7468 12.3977 10.8523C12.5032 10.9577 12.5625 11.1008 12.5625 11.25V15.8916L13.8525 14.6025C13.958 14.4972 14.1009 14.438 14.25 14.438C14.3991 14.438 14.542 14.4972 14.6475 14.6025Z"
                  fill="white"/>
            </svg>

          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "ProgramContent",
  props: {
    isMaterial: {
      type: Boolean,
      default: false
    },
    program: {
      type: Object
    }
  },
  data() {
    return {
      activeSpeaker: null,
      prevSpeaker: null,
      nextSpeaker: null
    }
  },
  computed: {
    getSpeakersCount() {
      return this.program.speaker.length
    },
    getSpeakersRows() {
      const speakers = this.program.speaker.length
      return speakers * 2 >= 6 ? 6 : speakers * 2
    },
    getSpeakerByRow() {
      const speakers = this.program.speaker.length
      if (speakers === 1) {
        return 12
      } else if (speakers === 2) {
        return 6
      } else {
        return 4
      }
    }
  },
  methods: {
    showSpeaker(id) {
      console.log(123)
      const index = _.findIndex(this.program.speaker, item => item.id === id)
      if (index !== -1) {
        this.activeSpeaker = this.program.speaker[index]
        this.$modal.show('speaker_' + this.program.id)
        if (this.program.speaker[index + 1]) {
          this.nextSpeaker = this.program.speaker[index + 1]
        } else {
          this.nextSpeaker = null
        }
        if (this.program.speaker[index - 1]) {
          this.prevSpeaker = this.program.speaker[index - 1]
        } else {
          this.prevSpeaker = null
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.program {
  &__info {
    &::v-deep {
      ul {

      }

      h3 {
        font-size: rem(21);
        color: #282938;
        font-weight: 500;
      }
    }
  }

  &__avatar {
    width: 110px;
    height: 110px;
    //margin: 0 auto;
    border-radius: 10em;
    position: relative;
    overflow: hidden;
    min-width: 110px;
    @include media(992) {
      margin: 0 auto;
    }
    @include media(768) {
      width: 80px;
      height: 80px;
      min-width: 80px;
    }

    &_small {
      width: 90px;
      height: 90px;
      min-width: 90px;
    }

    img {
      max-width: 100%;
      vertical-align: top;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__speaker {
    cursor: pointer;
  }

  &__name {
    font-weight: 700;
    padding-top: rem(14);
    color: #1D2130;
  }

  &__cont {
    padding-left: rem(16);
    @include media(992) {
      text-align: center;
      padding-left: 0;
    }
  }

  &__job {
    font-weight: 700;
    font-size: rem(14);
    color: #1D2130;
    opacity: 0.7;
  }

  &__link {
    a {
      text-decoration: none;
      color: #52B5E0;
      font-size: rem(12);
      font-weight: 300;
    }
  }

  &__title {
    font-weight: 500;
    font-size: rem(18);
    color: #282938;
  }

  &__theme {
    padding-top: rem(5);
    font-size: rem(14);

    &::v-deep {
      ul {
        padding: 0;
        margin: 0;
      }

      @include media(768) {
        padding: 0 0px;
      }
    }
  }
}

.speaker {
  &__name {
    font-weight: 500;
    font-size: rem(18);
    padding-top: rem(12);
    color: #1D2130;
  }

  &__job {
    font-weight: 400;
    font-size: rem(14);
    line-height: rem(16);
    color: #1D2130;
    opacity: 0.7;
    padding-top: rem(6);
  }

  &__line {
    border-bottom: 2px solid #ECECF1;
    padding: rem(6) 0;
  }

  &__text {
    overflow: hidden;
    padding-top: rem(12);
    font-size: rem(14);
    font-weight: 300;
    color: #282938;

    &::v-deep {
      * {
        font-size: rem(14);
        font-weight: 300;
        color: #282938;
      }
    }
  }

  &__replace {
    cursor: pointer;
    margin-top: rem(10);

    &_prev {
      svg {
        transform: rotate(180deg);
      }
    }

    span {
      font-size: rem(14);
      font-weight: 200;
      color: #282938;
    }

    svg {
      height: auto;
      vertical-align: middle;
    }
  }
}
</style>
